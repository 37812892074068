import { TicketManagementPortalConfiguration } from './ticket-management-portal-configuration.model';
import { InjectionToken } from '@angular/core';

export const APP_CONFIG = new InjectionToken<TicketManagementPortalConfiguration>('TicketManagementPortalConfiguration');
export const ApplicationConfiguration: TicketManagementPortalConfiguration = {
    // title: 'Staples Center',
    // subtitle: 'Seat Relocation Portal',
    isProcessActive: true,
    topbar: {
        topbarLogo: {
            src: '../../assets/img/premium_logo_white.png',
            alt: 'Crypto.com Arena Premium'
        },
        topbarLogoPrint: {
            src: '../../assets/img/logo_black.png'
        },
        includeInfoIcon: true,
        includeSwitchTheme: false
    },
    includeSalesRep: {
        title: 'Your Premium Services Manager'
    },
    footer: {
        copyrightText: '2022 3D Digital Venue by Mobile Media Content (MMC).'
    }

};

import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { UserService } from 'src/app/services';

@Injectable()
export class AddSeasonInterceptor implements HttpInterceptor {

  constructor(private userService: UserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    console.log('--- Add Season Interceptor', this.userService.currentSeason);
    const seasonBadFormat = this.userService.currentSeason;
    const season = (seasonBadFormat) ? seasonBadFormat.replace(/\s/g, '-') : '';
    let finalReq = req;
    if (season) {
      finalReq = req.urlWithParams.includes('?') ? req.clone({ url: `${req.urlWithParams}&season=${season}`})
        : req.clone({ url: `${req.urlWithParams}?season=${season}`});
    }

    return next.handle(finalReq);
  }
}
import { Injectable } from '@angular/core';
import { DVMService } from '../services/dvm.service';

@Injectable()
export class GeneralHandler {
    constructor(private event, protected dvmService: DVMService) {
        this.dvmService.subscribeHandler(event, this.handle.bind(this));
    }

    protected handle(...args) {}

}

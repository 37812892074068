import { Component, OnInit, Input, Inject, HostListener } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { FlowControlService } from '../services/flow-control.service';
import { DigitalvenueConfiguration } from '../digitalvenue-configuration.model';
import { APP_CONFIG, DVM_CONFIG } from '../digitalvenue.configuration';
import { CustomerPlan } from 'src/app/shared/models';
import * as globals from '../globals';
import { DVMService } from '../services/dvm.service';
declare var $: any;
import * as $ from 'jquery';
import { DVMConfiguration } from '../dvmconfiguration.model';
import { Popover3dviewService } from '../services/popover-3dview.service';


@Component({
  selector: 'app-main-interface',
  templateUrl: './main-interface.component.html',
  styleUrls: ['./main-interface.component.scss']
})
export class MainInterfaceComponent implements OnInit {

  @Input() selectedSeats: object[];
  @Input() isSelectionAllowed;
  customerPlan: CustomerPlan;
  _previewPlan: string;
  globals = globals;
  innerWidth = window.innerWidth;

  // Maps
  currentMap: any;
  selectableMapsArray: Array<object>;

  get isView3DOpen() {
    if (!this.dvmService.viewer3d || !this.dvmService.viewer3d.getViewId()) {
      return false;
    }
    return true;
  }

  get sector() {
    return null;
  }

  get seat() {
    return null;
  }

  get blockSelected() {
    return null;
  }

  /**
   * Event that detects the window size
   * @param event window resize
   */
  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    // gets the window width on reize
    this.innerWidth = window.innerWidth;
  }

  get subInterfacePlacement() {
    if (globals.isMobile()) {
      return 'top';
    }
    return 'left';
  }

  get previewPlan(): string {
    return this._previewPlan;
  }

  get hideClubPreviews() {
    if (this.dvmService.viewer) {
      return this.isView3DOpen || this.dvmService.viewer.getMapId() !== this.dvmConfig.map_id ||
      !this.dvmService.viewer.getMapId();
    }
    return null;
  }

  get suitesTablesText() {
      const nodeId = this.dvmService.viewer3d ? this.dvmService.viewer3d.getViewId() : '';
      return this.flowControl.getSuitesLoungesExtraText(nodeId);
  }

  constructor(@Inject(APP_CONFIG) public dvConfig: DigitalvenueConfiguration,
              @Inject(DVM_CONFIG) public dvmConfig: DVMConfiguration,
              public popover3dview: Popover3dviewService,
              public dvmService: DVMService,
              private userService: UserService,
              public flowControl: FlowControlService) {
    this.selectableMapsArray = this.flowControl.getAllMaps();

  }

  popoverMouseEnter() {
    this.popover3dview.deleteTimer();
  }

  popoverMouseLeave() {
    this.popover3dview.setTimer();
  }

  isRelocationAvailable(date: string) {
    const appointmentTimestamp = new Date(date).getTime();
    const now = new Date().getTime();
    return (now >= appointmentTimestamp) ? true : false;
  }

  closeGuideOverlay() {
    document.getElementById('guide-overlay').style.display = 'none';
    this.flowControl.guideOverlayClosed = true;
  }

  isGuideOverlayAvailable() {
    return this.isRelocationAvailable(this.customerPlan.appointment_date_from) && !this.isSelectionAllowed;
    // return false;
  }

  open3DView(viewId) {
    this.dvmService.load3DView(viewId);
    const tooltip: HTMLElement = document.querySelector('#tooltip');
    tooltip.removeAttribute('data-show');
  }

//   public open3DView(viewId) {
//     const loadOptions = JSON.parse(JSON.stringify(this.dvmConfig));
//     loadOptions.view_id = viewId;
//     loadOptions.venue_id = this.dvmConfig.venue_id;
//     this.dvmService.viewer3d.loadView3d(loadOptions);
// }

  ngOnInit() {
    this.customerPlan = this.userService.getCustomerPlanById(this.flowControl.currentPlan);
    this.flowControl.guideOverlayClosed = !this.isGuideOverlayAvailable();
    if (this.flowControl.upgradePlan) {
      this.customerPlan.plan_change_availability.forEach(element => {
        if (element.id === this.flowControl.upgradePlan) {
          this._previewPlan = element.name;
        }
      });
    } else {
      this._previewPlan = this.customerPlan.plan.name;
    }

    // Request and place thumbnail

    const generatePreviews = () => {
      let venue_id = this.dvmConfig.venue_id;
      if (this.flowControl.currentVenue) {
        venue_id = this.flowControl.getCurrentMap();
      }
      this.dvmService.viewer3d.getThumbnail({ venue_id,
        view_id: 'S_SMC(INT)' }, true).then((img) => {
          $('#smc-preview').html('');
          $('#smc-preview').css('background-image', 'url("' + img + '")');
      });
      this.dvmService.viewer3d.getThumbnail({ venue_id,
        view_id: 'S_Lexus(INT)' }, true).then((img) => {
        $('#lexus-preview').html('');
        $('#lexus-preview').css('background-image', 'url("' + img + '")');
      });
    };

    if (!this.dvmService.isViewer3dSubjectInitialized) {
      this.dvmService.viewer3dSubject.subscribe(() => {
        generatePreviews();
      });
    } else {
      generatePreviews();
    }

    this.dvmService.configurationChangedSuject.subscribe(
      s => { generatePreviews(); }
    );
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewChecked() {
    let viewer;
    let viewer3d;

    if (this.dvmService.isViewerSubjectInitialized) {
      viewer = this.dvmService.viewer;
    }

    if (this.dvmService.isViewer3dSubjectInitialized) {
      viewer3d = this.dvmService.viewer3d;
    }

    document.getElementById('guide-overlay').style.height = document.getElementById('main-interface').offsetHeight + 'px';
    document.getElementById('guide-overlay').style.width = document.getElementById('main-interface').offsetWidth + 'px';
    if (!globals.isMobile()) {
      // document.getElementById('main-interface').style.height = (document.getElementById('main-interface').offsetWidth / 1.764) + 'px';
      if (viewer) {
        viewer.flags.fixed_aspect_ratio = true;
      }
      if (viewer3d) {
        viewer3d.flags.fixed_aspect_ratio = true;
      }

      document.getElementById('guide-overlay-text').style.marginTop =
    (document.getElementById('guide-overlay').offsetHeight / 2) -
    (document.getElementById('guide-overlay-text').offsetHeight / 2) - 50 + 'px';
    } else {
      if (viewer) {
        viewer.flags.fixed_aspect_ratio = false;
      }
      if (viewer3d) {
        viewer3d.flags.fixed_aspect_ratio = false;
        // const hh = window.innerHeight - ($("#bottom-interface").height() + $("#sub-interface").height());
        // document.getElementById('viewer3d-container').style.height = hh - 50 + 'px';
      }
      if (!document.getElementById('main-interface').classList.contains('show-tickets')) {
        const hh = window.innerHeight - ($("#bottom-interface").height() + $("#sub-interface").height());
        document.getElementById('main-interface').style.height = hh - 70 + 'px';
      }
      document.getElementById('guide-overlay-button').style.marginTop =
    (document.getElementById('guide-overlay').offsetHeight / 2) - 15 + 'px';
    }
  }
}

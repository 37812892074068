import { Component, Inject, OnInit } from '@angular/core';
import { DjangoSessionAuthenticationService, ErrorJsonService } from '../services';
import { Router } from '@angular/router';
import { APP_CONFIG } from '../configuration/ticket-management-portal-configuration';
import { TicketManagementPortalConfiguration } from '../configuration/ticket-management-portal-configuration.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;
  user$;
  isFormInvalid = false;
  errorText: string;

  isProcessEnabled = false;
  processDisabledText = {
    "title": "Our 2021 Select-A-Seat Event has now concluded.",
    "text": "Thank you for your continued support!"
}

  constructor(@Inject(APP_CONFIG) private appConfig: TicketManagementPortalConfiguration,
              private auth: DjangoSessionAuthenticationService,
              private errorCodeService: ErrorJsonService,
              private router: Router) { }
  ngOnInit() {
    console.log('LOGIN');
    if (this.appConfig.isProcessActive) {
      this.isProcessEnabled = this.appConfig.isProcessActive;
    }
  }

  /**
   * Checks if the user exists. If not, throws an error to the view
   */
  login() {
    // subscribe to the login method on the auth service
    this.auth.login({ username: this.username, password: this.password }).subscribe(
      success => {
        this.router.navigate(['/home']);
      },
      error => {
        // if no user exist, shows an error 1000 to the view
        this.isFormInvalid = true;
        this.errorCodeService.getErrorByCode(1000).subscribe(
          errorText => {
            this.errorText = errorText;
          }
        );
      }
    );
  }

}

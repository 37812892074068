import { AfterViewInit, Component, Input, OnInit, Inject } from '@angular/core';
import { FlowControlService } from '../services/flow-control.service';
import { UserService, DjangoSessionAuthenticationService } from 'src/app/services';
import { ColoringService } from '../services/coloring.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClickSeatselectionHandler } from '../handlers/click-seatselection.handler';
import { LoadSeatselectionHandler } from '../handlers/load-seatselection.handler';
import { PopoverCreateHandler } from '../handlers/popover-create.handler';
import { PopoverDestroyHandler } from '../handlers/popover-destroy.handler';
import { DVMService } from '../services/dvm.service';
import { PricingService } from '../services/pricing.service';
import { NavigationHandler } from '../handlers/navigation.handler';
import { Subscription } from 'rxjs';
declare var require: any;

@Component({
  selector: 'app-seatselection',
  templateUrl: './seatselection.component.html',
  styleUrls: ['./seatselection.component.scss'],
  providers: [
    ClickSeatselectionHandler,
    LoadSeatselectionHandler,
    PopoverCreateHandler,
    PopoverDestroyHandler,
    NavigationHandler,
  ]
})
export class SeatSelectionComponent implements OnInit, AfterViewInit {
  planId: number;
  upgradePlan: number;
  isSelectionAllowed: boolean;
  discardedSeats: object;
  currentMap: any;
  selectableMapsArray: Array<object>;
  subscription: Subscription;

  get selectedSeats() {
    const selectedSeats = [];
    // tslint:disable-next-line: forin
    for (const seat in this.flowControl.Basket) {
      selectedSeats.push(this.flowControl.Basket[seat]);
    }
    selectedSeats.sort((a, b) => a.price.price - b.price.price);
    return selectedSeats;
  }

  constructor(private flowControl: FlowControlService,
              private pricing: PricingService,
              private route: ActivatedRoute,
              private router: Router,
              private userService: UserService,
              private dvmService: DVMService,
              private clickHandler: ClickSeatselectionHandler,
              private loadHandler: LoadSeatselectionHandler,
              private popoverCreateHandler: PopoverCreateHandler,
              private popoverDestroyHandler: PopoverDestroyHandler,
              private navigationHandler: NavigationHandler,
              private auth: DjangoSessionAuthenticationService,
              private coloring: ColoringService) {
    this.selectableMapsArray = Object.values(this.flowControl.getAllMaps());

  }

  ngAfterViewInit() {
    // this.coloring.applyInterfaceColors();
  }

  ngOnInit() {
    this.userService.sendUrl(this.router.url);
    this.isSelectionAllowed = true;
    this.userService.setSelectionAllowed(true);
    this.planId = parseInt(this.route.snapshot.paramMap.get('planId'), 10);
    this.upgradePlan = parseInt(this.route.snapshot.paramMap.get('upgrade'), 10);
    this.flowControl.currentPlan = this.planId;
    if (this.upgradePlan && this.upgradePlan !== this.planId) {
      this.flowControl.upgradePlan = this.upgradePlan;
      // Preview Upgrade Modal
      // this.modals.upgradeModal();
    } else {
      this.flowControl.upgradePlan = null;
    }
    if (this.route.snapshot.paramMap.get('upgradePlan')) {
      this.upgradePlan = parseInt(this.route.snapshot.paramMap.get('upgradePlan'), 10);
    }
    this.discardedSeats = this.flowControl.discardedSeats;
    const customerPlan = this.userService.getCustomerPlanById(this.planId);
    const plansInit = (plan) => {
      if (plan.transaction != null) {
        switch (plan.transaction.status) {
          case 'PH':
            this.router.navigate(['/checkout', this.flowControl.currentPlan]);
            break;
            case 'PR':
            this.router.navigate(['/summary', this.flowControl.currentPlan]);
            break;
          default:
            break;
        }
      }
      this.flowControl.maxAddons = plan.addons_allowed;
    };
    if (!customerPlan) {
      this.auth.getUserLogged$().subscribe(
        userData => {
          this.userService.setUser(userData);
          plansInit(userData['plans']);
        },
        error => {
          // this.modals.errorModal(error.message);
          console.error(error);
        }
      );
    } else {
      plansInit(customerPlan);
    }

    if (!this.dvmService.isViewerSubjectInitialized) {
      this.dvmService.restartDVM();
    } else {
      this.dvmService.restartDVM(this.selectableMapsArray[this.flowControl.currentVenue]['venue']);
    }
    
    // this.loadCurrentMap();
    this.pricing.init(Object.values(this.discardedSeats));
    this.pricing.setMaxPrice( JSON.parse(JSON.stringify(this.userService.getCustomerPlanById(this.planId).customer_plan_seats)));
  }


}

import { Injectable } from '@angular/core';
import { DVMService } from './dvm.service';
import { IsolatedSeatsService } from './isolated-seats.service';
import { UserService } from 'src/app/services';
import { FlowControlService } from './flow-control.service';

@Injectable({
  providedIn: 'root'
})
export class IsolatedSeatsApiService {

  pendingSeats: Array<any>;
  discardedSeats: any;

  constructor(private dvmService: DVMService,
              private userService: UserService,
              private flowService: FlowControlService) {
    this.pendingSeats = [];
   }

   public setDiscardedSeatPriceType(seats: Array<any>): any {
     const customerPlan = this.userService.getCustomerPlanById(this.flowService.currentPlan);
     return null;
   }


  /**
   * SETS THE PENDING SEATS
   * @param seats to be in pending
   */
  public setPendingSeats(seats: Array<any>): void {
    this.pendingSeats = Object.values(seats);
  }

  public getPendingSeats(): any {
    return this.pendingSeats;
  }

  /**
   * SETS THE DISCARDED SEATS
   */
  public setDiscardedSeats(seats: any): void {
    // this.setDiscardedSeatPriceType(seats);
    this.discardedSeats = seats;
  }

  public getDiscardedSeats(): any {
    return this.discardedSeats;
  }

  /**
   * PRINT ISOLATED SEATS IN THE SEATMAP FROM API ISOLATED RESULT
   * @param isolatedSeats to print on map
   */
  public printIsolatedSeats(isolatedSeats: Array<any>): void {
    const actuallyPending = this.dvmService.viewer.getNodesByTag('seat', 'pending');
    // reset pending color view
    this.dvmService.viewer.setNodesTag(actuallyPending, null);
    this.dvmService.viewer.setNodesTag(isolatedSeats, 'pending');
  }
}

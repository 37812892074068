import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap';
import { CongratulationsModalComponent } from './congratulations-modal/congratulations-modal.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { RestartModalComponent } from './restart-modal/restart-modal.component';
import { LexusModalComponent } from './lexus-modal/lexus-modal.component';
import { ParkingSlotModalComponent } from './parking-slot-modal/parking-slot-modal.component';
import { PanoViewModalComponent } from './pano-view-modal/pano-view-modal.component';
import { CustomerPlan } from '../models';
import { Subject } from 'rxjs';
import { PlanSeatElementModalComponent } from './plan-seat-element-modal/plan-seat-element-modal.component';
import { ExtraModalComponent } from './extra-modal/extra-modal.component';
import { InfoModalComponent } from './info-modal/info-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalsService {
  bsModalRef: BsModalRef;

  constructor(public modalService: BsModalService) { }

  public congratulationsModal(): void  {
    const config = {
      animated: true,
      keyboard: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered'
    };
    this.bsModalRef = this.modalService.show(CongratulationsModalComponent, config);
  }

  // public checkoutModal() {
  //   const config = {
  //     animated: true,
  //     keyboard: false,
  //     ignoreBackdropClick: true,
  //     class: 'modal-dialog-centered'
  //   };
  //   this.bsModalRef = this.modalService.show(CheckoutModalComponent, config);
  // }

  public errorModal(errorMessage): void  {
    const initialState = {
      animated: true,
      keyboard: false,
      ignoreBackdropClick: true,
      message: errorMessage
    };
    this.bsModalRef = this.modalService.show(ErrorModalComponent, { class: 'modal-dialog-centered', initialState });
  }

  public restartModal(planId, interval): void  {
    const resetConfig: ModalOptions = {
      animated: true,
      keyboard: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered',
      initialState: {
        planId,
        interval
      }
    };
    this.bsModalRef = this.modalService.show(RestartModalComponent, resetConfig);
  }

  public lexusModal(success): void  {
    const config: ModalOptions = {
      animated: true,
      keyboard: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered',
      initialState: {
        success
      }
    };
    this.bsModalRef = this.modalService.show(LexusModalComponent, config);
  }

  public parkingSlotModal(success): void  {
    const config: ModalOptions = {
      animated: true,
      keyboard: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered',
      initialState: {
        success
      }
    };
    this.bsModalRef = this.modalService.show(ParkingSlotModalComponent, config);
  }

  public panoViewModal(seatId): void  {
    let heigh;
    if (this.isMobile() || window.innerWidth < 1200) {
      heigh = document.getElementById('seat-modal').offsetHeight;
    }
    const config: ModalOptions = {
      animated: true,
      keyboard: false,
      ignoreBackdropClick: true,
      class: 'modal-lg modal-dialog-centered',
      initialState: {
        seatId,
        parentModalHeight: heigh
      }
    };

    this.bsModalRef = this.modalService.show(PanoViewModalComponent, config);
  }

  public planSeatElementModal(customerPlan: CustomerPlan, accordionToggleSubject: Subject<number>): BsModalRef {
    const config: ModalOptions = {
      animated: true,
      keyboard: false,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered',
      initialState: {
        customerPlan,
        accordionToggleSubject
      }
    };

    return this.modalService.show(PlanSeatElementModalComponent, config);
  }

  public extraModal(): void {
    const config: ModalOptions = {
      animated: true,
      keyboard: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered',
      initialState: {
        // success,
        // reject
      }
    };

    this.bsModalRef = this.modalService.show(ExtraModalComponent, config);
  }

  // public generalAdmissionModal() {
  //   const config = {
  //     animated: true,
  //     class: 'modal-dialog-centered'
  //   };
  //   this.bsModalRef = this.modalService.show(GeneralAdmissionModalComponent, config);
  // }

  public infoModal() {
    const infoConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered guide-modal'
    };
    this.modalService.show(InfoModalComponent, infoConfig);
  }

  // public backModal() {
  //   const infoConfig: ModalOptions = {
  //     animated: true,
  //     class: 'modal-dialog-centered'
  //   };
  //   this.modalService.show(BackModalComponent, infoConfig);
  // }

  // public justAddonsModal() {
  //   const infoConfig: ModalOptions = {
  //     animated: true,
  //     class: 'modal-dialog-centered'
  //   };
  //   this.modalService.show(JustAddonsModalComponent, infoConfig);
  // }

  // public guideModal() {
  //   const config: ModalOptions = {
  //     animated: true,
  //     keyboard: false,
  //     ignoreBackdropClick: true,
  //     class: 'modal-dialog-centered',
  //   };
  //   this.bsModalRef = this.modalService.show(GuideModalComponent, config);
  // }

  // public upgradeModal() {
  //   const config: ModalOptions = {
  //     animated: true,
  //     keyboard: false,
  //     ignoreBackdropClick: true,
  //     class: 'modal-dialog-centered',
  //   };
  //   this.bsModalRef = this.modalService.show(UpgradeModalComponent, config);
  // }

  /**
   * Checks if the navigator it's a mobile
   * @returns boolean
   */
  isMobile(): boolean {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  }
}

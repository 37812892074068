import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { SeatTransaction, Seat, CustomerPlan } from 'src/app/shared/models';
import { ThemeSelectorService, UserService, DjangoSessionAuthenticationService, ErrorJsonService } from 'src/app/services';
import { Router, ActivatedRoute } from '@angular/router';
import { MessagingService } from 'src/app/shared/modals/messaging.service';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { ExtraService } from 'src/app/digitalvenue/services/extra.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {
  planId: number;
  customerPlan: CustomerPlan;
  keptSeats = [];
  // tslint:disable-next-line: variable-name
  selectedSeats = [];
  discardedSeats = [];
  isDarkTheme: boolean;
  innerWidth = window.innerWidth;

  seatsToDiscard = [];
  discardHash = {};

  timerObservable = new Subject<string>();
  timer;

  transactionTimerLimit = 5; // In Minutes
  isTimerOver = false;
  interval;

  upgradedPlan = null;

  get isDiscardSeatsAvailable() {
    return (this.selectedSeats.length - this.discardedSeats.length - this.seatsToDiscard.length) > 0;
  }

  /**
   * Event that detects the window size
   * @param event window resize
   */
  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    // gets the window width on reize
    this.innerWidth = window.innerWidth;
  }

  get extrasCount() {
    return { parkingCountC: this.extrasService.parkingCountC,
      parkingCountW: this.extrasService.parkingCountW,
      lexusCount: this.extrasService.lexusCount };
  }

  get extrasPrices() {
    return {
      parking_c: this.extrasService.extraPrices.parking_c * this.extrasCount.parkingCountC,
      parking_w: this.extrasService.extraPrices.parking_w * this.extrasCount.parkingCountW,
      lexus: this.extrasService.extraPrices.lexus * this.extrasCount.lexusCount,
      total: this.extrasService.extraPrices.parking_c * this.extrasCount.parkingCountC +
      this.extrasService.extraPrices.parking_w * this.extrasCount.parkingCountW +
      this.extrasService.extraPrices.lexus * this.extrasCount.lexusCount
    };
  }

  /**
   * Gets the timer
   */
  get getTimer() {
    return this.timer;
  }

  constructor(private auth: DjangoSessionAuthenticationService,
              public extrasService: ExtraService,
              private messageService: MessagingService,
              private userService: UserService,
              private themeSelector: ThemeSelectorService,
              private router: Router,
              private routeParam: ActivatedRoute,
              private errorCodeService: ErrorJsonService,
              private modalService: ModalsService) { }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.timerObservable.unsubscribe();
  }

  editExtras(extraType: 'parkingC' | 'parkingW' | 'lexus', isIncrement: boolean) {
    let action;
    if (extraType === 'parkingC') {
      action = 'parkingCountC';
    } else if (extraType === 'parkingW') {
      action = 'parkingCountW';
    } else if (extraType === 'lexus') {
      action = 'lexusCount';
    }
    if (isIncrement) {
      this.extrasService[action]++;
    } else {
      if (this.extrasService[action] > 0) {
        this.extrasService[action]--;
      }
    }
  }

  setSeatToDiscard(seat) {
    this.seatsToDiscard.push(seat.id);
    this.discardHash[seat.id] = true;
  }

  removeSeatFromDiscard(seat) {
    this.seatsToDiscard.forEach(arraySeat => {
      if (arraySeat === seat.id) {
        this.seatsToDiscard.splice(this.seatsToDiscard.indexOf(arraySeat));
      }
    });
    this.discardHash[seat.id] = false;
  }

  ngOnInit() {
    this.planId = parseInt(this.routeParam.snapshot.paramMap.get('planId'), 10);
    // gets the customer plan by id from the service
    this.customerPlan = this.userService.getCustomerPlanById(this.planId);
    console.log(this.customerPlan);
    if (this.customerPlan.transaction != null) {
      if (this.customerPlan.transaction.status === 'PR') {
        // if transaction status it's 'PR' means that is pending reserved and has to be redirected to the summary
        this.router.navigate(['/summary', this.planId]);
      } else {
        // if transaction it's diferrent than 'PR' then filter the seats
        this.filterPlanSeats(this.customerPlan);
        if (this.customerPlan.transaction.related) {
            // tslint:disable:no-string-literal
          this.upgradedPlan = this.customerPlan.transaction.related['plan'];
        }
      }
    } else {
      // if transaction it's null redirects to the landing page
      this.router.navigate(['/home']);
    }
    // Subscribes to the time observable to get the timer
    this.timerObservable.subscribe(
      timer => {
        this.timer = timer;
      }
    );
    this.setTimer();
    this.setTimerInterval();
  }

  /**
   * Parses the timestamp into days, hours, minutes and seconds
   * @param timeStamp to parse the date into string
   * @returns the formated date
   */
  time2string(timeStamp) {
    // Days
    const days = Math.floor(timeStamp / 86400);
    timeStamp -= days * 86400;
    // Hours
    const hoursNum = Math.floor(timeStamp / 3600) % 24;
    const hours = (hoursNum < 10 ? '0' : '') + hoursNum;
    timeStamp -= hoursNum * 3600;
    // Minutes
    const minutesNum = Math.floor(timeStamp / 60) % 60;
    const minutes = (minutesNum < 10 ? '0' : '') + minutesNum;
    timeStamp -= minutesNum * 60;
    // Seconds
    const secondsNum = Math.floor(timeStamp % 60);
    const seconds = (secondsNum < 10 ? '0' : '') + secondsNum;
    return minutes + ':' + seconds;
  }

  /**
   * Sets the timer and updates the observable
   */
  setTimer(): void {
    // Gets the appointment date
    const date = this.customerPlan.transaction.created;
    // Gets the timestamp of appointment date
    const appointmentTimestamp = new Date(date).getTime();
    // Gets the current date
    const now = new Date().getTime();
    // Gets the difference between current date and appointment date
    let diff = ((this.transactionTimerLimit * 60000) - (now - appointmentTimestamp)) / 1000;
    if (diff < 0) {
      diff = 0;
      // Control so it pops one time
      if (!this.isTimerOver) {
        this.transactionExpiredModal();
        this.isTimerOver = true;
      }
    }
    // Parses the difference result
    const timeString = this.time2string(diff);
    // Updates the subject with the new value
    this.timerObservable.next(timeString);
  }

  transactionExpiredModal() {
    let transaction = this.customerPlan.transaction;
    if (!transaction.master) {
        transaction = transaction.related;
    }
    this.messageService.info(
      'Oops!',
      `Your transaction has expired. Your new seats have been released,
      but your old seats are still reserved in your account.
      Please, click the button below to restart.`,
      'Restart',
      () => {
        // subscribe and update the user data
        this.userService.cancelTransaction(transaction.id).subscribe(
            (response) => {
                this.auth.updateUserData().subscribe(
                    userData => {
                        // redirects to the landing page
                        this.router.navigate(['/home']);
                    },
                    error => {
                        console.error(error);
                        this.router.navigate(['/home']);
                    }
                );
            },
            (error) => {
                console.log(error);
                this.router.navigate(['/home']);
            }
        );
      }
    );
  }

  /**
   * Sets the timer with an interval of 1 seg (to do a chronometer)
   */
  setTimerInterval(): void {
    this.interval = setInterval(() => {
      this.setTimer();
    }, 1000);
  }

  formatPrice(price: number) {
    return price.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
  }

  absolute(num: number) {
    return Math.abs(num);
  }

  /**
   * Loops the array selectedSeats and sums the price of all seats
   * @returns count, the selected total price
   */
  get totalPriceSelected(): number {
    let count = 0;
    for (const seat of this.selectedSeats) {
      if (!this.discardHash[seat.id]) {
        count += seat.price;
      }
    }
    for (const seat of this.keptSeats) {
      if (!this.discardHash[seat.id]) {
        count += seat.price;
      }
    }
    // return Number.parseFloat(count + '').toFixed(2);
    return count;
  }

  /**
   * Loops the array discardedSeats and sums the price of all seats
   * @returns count, the discarded total price
   */
  get totalPriceDiscarded(): number {
    let count = 0;
    for (const seat of this.discardedSeats) {
      count += seat.price;
    }
    for (const seat of this.keptSeats) {
      if (!this.discardHash[seat.id]) {
        count += seat.price;
      }
    }
    // return Number.parseFloat(count + '').toFixed(2);
    return count;
  }

  /**
   * Substract the total price of discarded and selected seats,
   * @returns object with the absolute price and a boolean
   */
  get totalPrice(): { 'isRefund': boolean, 'price': number } {
    const priceSelected = this.totalPriceSelected;
    // const priceDiscarded = this.totalPriceDiscarded;
    // const price = priceSelected - priceDiscarded;
    const price = priceSelected;
    let isRefund;
    // checks if it's to pay or refund
    // (price >= 1) ? isRefund = true : isRefund = false;
    isRefund = false;
    return { isRefund, price };
  }

  /**
   * Displays the first confirmation modal
   */
  openCheckoutModal(): void {
    if (this.getTimer === '00:00') {
      this.transactionExpiredModal();
    } else {
      const title = 'Continue';
      const message = `By clicking confirm, your old seats will be placed in open inventory and your new seats will be moved to
      your account. Once you confirm, this action cannot be undone. Do you want to confirm your new seat selection?`;
      const acceptBtnName = 'Confirm';
      const closeBtnName = 'Close';
      this.messageService.info(title, message, acceptBtnName, () => {
        this.openCheckout2Confirmation();
      }, closeBtnName);
    }
  }

  openCheckout2Confirmation(): void {
    const title = 'Continue';
    const message = 'Are you sure you want to continue?';
    const acceptBtnName = 'Confirm';
    const closeBtnName = 'Close';
    this.messageService.info(title, message, acceptBtnName, () => {
      this.createReservedTransaction(this.customerPlan);
    }, closeBtnName);
  }

  /**
   * Creates the transaction in status reserved ( PR ) and redirects to the summary page,
   * if error displays an error.
   * @param customerPlan to get the selected seats
   */
  createReservedTransaction(customerPlan: CustomerPlan): void {

    const currentParkingC = Object.keys(this.extrasService.extraHash.parkingHashC).length;
    const currentParkingW = Object.keys(this.extrasService.extraHash.parkingHashW).length;
    const currentLexus = Object.keys(this.extrasService.extraHash.lexusHash).length;
    const newParkingC = this.extrasService.parkingCountC;
    const newParkingW = this.extrasService.parkingCountW;
    const newLexus = this.extrasService.lexusCount;

    // transaction object, needed the action, plan id and the array of seats
    // const transaction = {'action': 'reserve', 'plan_id': customerPlan.id, 'seats': seats};
    // console.log(transaction);
    // subscribe to the function and if it's successful then will subscribe to another observable
    // to update the user and then redirects to the summary page
    let transaction = customerPlan.transaction;
    if (!transaction.master) {
      transaction = transaction.related;
    }

    // Fill Array helper function, needed to create the extras array
    const fillArray = (value, len) => {
      if (len === 0) { return []; }
      let a = [value];
      while (a.length * 2 <= len) { a = a.concat(a); }
      if (a.length < len) { a = a.concat(a.slice(0, len - a.length)); }
      return a;
    };

    // Extras action control, calls editTransactionSeats with the correct parameters for each case
    const editExtra = (extraType: 'parkingC' | 'parkingW' | 'lexus', count: number, success = () => { }) => {
      let areaName;
      let hashName;
      if (extraType === 'parkingC') {
        areaName = 'S_Lot C';
        hashName = 'parkingHashC';
      } else if (extraType === 'parkingW') {
        areaName = 'S_Lot W';
        hashName = 'parkingHashW';
      } else if (extraType === 'lexus') {
        areaName = 'S_Lexus Club';
        hashName = 'lexusHash';
      }
      if (count > 0) {
        // Add new extras
        const seats = fillArray(areaName, count);
        this.userService.editTransactionSeats(transaction.id, seats, 'add').subscribe(response => success(),
        error => {
          console.error(error);
          this.modalService.errorModal(error.error.message);
          // If error, shows a modal with the error code
          // this.errorCodeService.getErrorByCode(1005).subscribe(
          //   errorText => {
          //     // displays an error modal
          //     this.modalService.errorModal(errorText);
          //   }
          // );
        });
      } else if (count < 0) {
        // Remove extras
        const seats = Object.keys(this.extrasService.extraHash[hashName]).splice(0, Math.abs(count));
        this.userService.editTransactionSeats(transaction.id, seats, 'remove').subscribe(response => success(),
        error => {
          console.error(error);
          this.modalService.errorModal(error.error.message);
          // If error, shows a modal with the error code
          // this.errorCodeService.getErrorByCode(1005).subscribe(
          //   errorText => {
          //     // displays an error modal
          //     this.modalService.errorModal(errorText);
          //   }
          // );
        });
      } else {
        success();
      }
    };

    // Calls reserveTransaction to change the status of a transaction to 'Reserved' or 'Confirmed'
    const reserveTransaction = () => {
      this.userService.reserveTransaction(transaction.id).subscribe(
        data => {
          this.auth.updateUserData().subscribe(
            userData => {
              this.router.navigate(['/summary', this.planId]);
            }
          );
        },
        // Displays error modal
        error => {
          const errorMessage = error.error;
          this.modalService.errorModal(errorMessage.message);
          // this.errorCodeService.getErrorByCode(1003).subscribe(
          //   errorText => {
          //     this.modalService.errorModal(errorText);
          //   }
          // );
        });
    };

    // Parking and Lexus selections are checked and processed
    const parkingPromiseC = new Promise((success) => { editExtra('parkingC', newParkingC - currentParkingC, success); });
    const parkingPromiseW = new Promise((success) => { editExtra('parkingW', newParkingW - currentParkingW, success); });
    const lexusPromise = new Promise((success) => { editExtra('lexus', newLexus - currentLexus, success); });

    // When extras are checked and processed, deleted seats are processed and the transaction is reserved
    Promise.all([parkingPromiseC, parkingPromiseW, lexusPromise]).then(() => {
      if (this.seatsToDiscard.length) {
        this.userService.editTransactionSeats(transaction.id, this.seatsToDiscard, 'remove').subscribe(
          response => {
            reserveTransaction();
          },
          error => {
            const errorMessage = error.error;
            if (errorMessage.code === 'TCRRS03') {
              for (let i = 0; i < errorMessage.seats.length; i++) {
                errorMessage.seats[i] = errorMessage.seats[i].split('S_')[1];
              }
              this.modalService.errorModal(`The selected seats are leaving isolated seats: ${errorMessage.seats}`);
            } else {
              console.error(error);
              this.modalService.errorModal(error.error.message);
              // If error, shows a modal with the error code
              // this.errorCodeService.getErrorByCode(1005).subscribe(
              //   errorText => {
              //     // displays an error modal
              //     this.modalService.errorModal(errorText);
              //   }
              // );
            }
          }
        );
      } else {
        reserveTransaction();
      }
    });
  }

  /**
   * Displays the restart modal
   */
  openRestartModal(): void {
    if (this.getTimer === '00:00') {
      this.transactionExpiredModal();
    } else {
      this.modalService.restartModal(this.planId, this.interval);
    }
  }

  /**
   * Filter the transaction and put the seats into their respective array
   * @param customerPlan to loop the transaction seats
   */
  filterPlanSeats(customerPlan: CustomerPlan): void {
    this.extrasService.init();
    this.keptSeats = [];
    this.selectedSeats = [];
    this.discardedSeats = [];
    const pushSeats = (transactionSeatsArray) => {
      transactionSeatsArray.forEach(element => {
        if (element.action === 'keep') {
          // if action is 'keep' means that it has to be as discarded and selected
          this.keptSeats.push(this.transactionSeat2Seat(element, this.formatPrice(element.price)));
        }
        // if action is 'keep' means that it has to be as discarded
        if (element.action === 'remove') {
          this.discardedSeats.push(this.transactionSeat2Seat(element, this.formatPrice(element.price)));
        }
        // if action is 'keep' means that it has to be as  selected
        if (element.action === 'add') {
          const seatSection = element.seat.split('_')[1].split('-')[0];
          if (seatSection === 'Lot C' && element.price > 0) {
            this.extrasService.extraHash.parkingHashC[element.seat] = element;
          } else if (seatSection === 'Lot W' && element.price > 0) {
            this.extrasService.extraHash.parkingHashW[element.seat] = element;
          } else if (seatSection === 'Lexus Club' && element.price > 0) {
            this.extrasService.extraHash.lexusHash[element.seat] = element;
          } else {
            this.selectedSeats.push(this.transactionSeat2Seat(element, this.formatPrice(element.price)));
          }
        }
      });
      this.extrasService.setExtraCount();
    };
    pushSeats(customerPlan.transaction.seat_transactions);
    if (customerPlan.transaction.related) {
      pushSeats(customerPlan.transaction.related.seat_transactions);
    }
    this.keptSeats.sort();
  }

  /**
   * Parses the transaction seat into seat object
   * @param transactionSeat to parse
   * @returns Seat object
   */
  transactionSeat2Seat(transactionSeat: SeatTransaction, priceString = null) {
    // splits the transaction seat
    const seat = transactionSeat.seat.split('_')[1].split('-');
    return { id: transactionSeat.seat, seat: seat[2], seat_row: seat[1], section: seat[0], price: transactionSeat.price, priceString };
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

  /**
   * Check if the browser it's mobile
   * @returns true in case of mobile, othewise false
   */
  isMobile(): boolean {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ThemeSelectorService } from 'src/app/services/theme-selector.service';
import * as globals from '../../../digitalvenue/globals';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {

  isDarkTheme: boolean;
  private guideStep = 1;
  stepsArray = Array.from(Array(this.maxSteps).keys(), (e, i) => i + 1);

  constructor(public bsModalRef: BsModalRef,
              private themeSelector: ThemeSelectorService,
              private storageService: LocalStorageService) {
  }

  get maxSteps() {
    if (globals.isMobile()) {
      return 9;
    } else {
      return 8;
    }
  }

  get imagePath() {
    if (globals.isMobile()) {
      return '../../../assets/img/guide/mobile/' + this.guideStep + '.png';
    } else {
      return '../../../assets/img/guide/' + this.guideStep + '.png';
    }
  }

  get imageDescription() {
    if (globals.isMobile()) {
      // return null;
      return this.imageDescriptionsMobile[this.guideStep];
    } else {
      return this.imageDescriptions[this.guideStep];
    }
  }

  imageDescriptionsMobile = {
    1: `<ul>
            <li><span class="text-bold font-bold">(...)</span> - Click here to expand your seating details where can preview its 3D views</li>
            <li><span class="text-bold font-bold">START RELOCATION</span> - If your relocation appointment date has arrived, you will be able to start the process by clicking on “Start Relocation”</li>
        </ul>`,
    2: `<ul>
            <li><span class="text-bold font-bold">DROPDOWN ('LAKERS')</span> - Select the desired map here</li>
            <li><span class="text-bold font-bold">RELOCATE</span> - Click on the “Relocate” button to discard those seats do you want to relocate</li>
        </ul>`,
    3: `<ul>
            <li><span class="text-bold font-bold">360 VIEW</span> - You can preview your current seating 3D view by clicking on the “360 View” button</li>
        </ul>`,
    4: `<ul>
            <li><span class="text-bold font-bold">UNDO</span> - You can change your decision by clicking on the “Undo” button</li>
            <li><span class="text-bold font-bold">SECTION MAP</span> - You can also browse the map to preview other 3D seat views of available seating</li>
            <li><span class="text-bold font-bold">SELECT NEW SEATS</span> - Once you are sure about your decision, click on “Select new seats”</li>
        </ul>`,
    5: `<ul>
            <li><span class="text-bold font-bold">SEATS</span> – Select your desired new seating</li>
            <li><span class="text-bold font-bold">REMOVE</span> – You can remove each seat if you want to re-think your choice</li>
            <li><span class="text-bold font-bold">BALANCE</span> – Your total balance will be automatically updated when selecting new seats or removing the selected ones</li>
            <li><span class="text-bold font-bold">CONFIRM</span> – Once you are sure about your choice, click on “Confirm”</li>
        </ul>`,
    6: `<ul>
            <li><span class="text-bold font-bold">SEE PREVIEW</span> – You can preview the Lexus Club here</li>
            <li><span class="text-bold font-bold">(+) & (-) BUTTONS</span> - Select the desired Lexus Club memberships and Parking Lot C passes</li>
        </ul>`,
    7: `<ul>
            <li><span class="text-bold font-bold">PREVIOUS SEATS</span> – Your old seating</li>
            <li><span class="text-bold font-bold">NEW SEATS</span> – Your new seating</li>
            <li><span class="text-bold font-bold">TRASH ICON</span> – This is the last moment when you can modify your seating or extras (see next step) selection</li>
        </ul>`,
    8: `<ul>
            <li><span class="text-bold font-bold">BALANCE</span> – Your total balance amount</li>
            <li><span class="text-bold font-bold">CHEKCOUT</span> – Proceed to checkout</li>
        </ul>`,
    9: `<ul>
            <li><span class="text-bold font-bold">CONGRATULATIONS</span> – The process is completed successfully!</li>
        </ul>`
  };

  imageDescriptions = {
    1: `<span class="text-bold font-bold">Welcome to the Crypto.com Arena Seat Relocation Portal. With this tutorial you will understand all the process in a few steps</span>
    <ul style='height: auto;'>
        <li>1. Click on this arrow to expand your seating details.</li>
        <li>2. You can check the 3D view of your currently seating.</li>
        <li>3. If your relocation appointment date has arrived, you will be able to start the process by clicking on “Start Relocation”</li>
    </ul>`,
    2: `<span class="text-bold font-bold">On this step, you will decide which seating do you want to relocate. In addition, you can browse the map to preview the availability of each section and preview each 3D view from seat, suite, or club of the whole venue.</span>
<ul>
    <li>1. Select the desired map between Laker / Kings / Clippers / Concert.</li>
    <li>2. Click on the “Relocate” button to discard those seats do you want to relocate.</li>
</ul>`,
    3: `
    <ul>
    <li>1. You can also preview your current seating 3D view by clicking them on the left panel</li>
    <li>2. Or by clicking on the thumbnail that appears when hovering</li>
    </ul>`,
    4: `
    <ul>
    <li>1. You can change your decision by clicking on the “Undo” button</li>
    <li>2. Once you are sure about your decision, click on “Select new seats”</li>
    </ul>`,
    5: `<span class="text-bold font-bold">On this step, you will decide which seating do you want to relocate. In addition, you can browse the map to preview the availability of each section and preview each 3D view from seat, suite, or club of the whole venue.</span>
<ul>
    <li>1. Select your desired new seating.</li>
    <li>2. You can remove each seat if you want to re-think your choice.</li>
    <li>3. Your total balance will be automatically updated when selecting new seats or removing the selected ones.</li>
    <li>4. Once you are sure about your choice, click on “Confirm selection”</li>
</ul>`,
    6: `<span class="text-bold font-bold">Select your extras</span>
<ul>
    <li>1. You can preview the Lexus Club here.</li>
    <li>2. Select the desired Lexus Club memberships and Parking Lot C passes</li>
    <li>3. Confirm your selection</li>
</ul>`,
    7: `<span class="text-bold font-bold">Checkout</span>
<ul>
    <li>1. Your old seating.</li>
    <li>2. Your new seating.</li>
    <li>3. This is the last moment when you can modify your extras selection</li>
    <li>4. Your total balance amount</li>
    <li>5. Proceed to checkout</li>
</ul>`,
    8: `<span class="text-bold font-bold">The relocation is completed successfully</span>
<ul>
    <li>1. Your total balance.</li>
    <li>2. You can print your summary and return home.</li>
</ul>`,

  };


  isStepActive(stepId) {
    return stepId === this.guideStep;
  }

  setStep(stepId) {
    this.guideStep = stepId;
  }

  isNextAvailable() {
    return this.guideStep < this.maxSteps;
  }

  isPrevAvailable() {
    return this.guideStep > 1;
  }

  nextStep() {
    if (this.isNextAvailable) {
      this.guideStep++;
    }
  }

  prevStep() {
    if (this.isPrevAvailable) {
      this.guideStep--;
    }
  }

  ngOnInit() {
  }

  /**
   * Hides the modal
   */
  confirm(): void {
    this.bsModalRef.hide();
    this.storageService.setItem('guideReaded', JSON.stringify(true));
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

}

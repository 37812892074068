import { Component, OnInit, Inject } from '@angular/core';
import { ThemeSelectorService } from '../../services/theme-selector.service';
import { UserService } from '../../services/user.service';
import { TicketManagementPortalConfiguration } from 'src/app/configuration/ticket-management-portal-configuration.model';
import { APP_CONFIG } from 'src/app/configuration/ticket-management-portal-configuration';

@Component({
  selector: 'app-sales-rep',
  templateUrl: './sales-rep.component.html',
  styleUrls: ['./sales-rep.component.scss']
})
export class SalesRepComponent implements OnInit {

  isDarkTheme: boolean;
  name;
  surname;
  email;
  phoneNumber;

  constructor(@Inject(APP_CONFIG) public app_config: TicketManagementPortalConfiguration,
              private themeSelector: ThemeSelectorService,
              private userService: UserService) { }

  ngOnInit() {
    // tslint:disable-next-line: no-string-literal
    const salesRep = this.userService.getUser()['sales_rep'];
    if (salesRep != null) {
      this.name = salesRep.first_name;
      this.surname = salesRep.last_name;
      this.email = salesRep.email;
      this.phoneNumber = salesRep.phone;
    }
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

}

import { Injectable } from '@angular/core';
import { CustomerPlan, Seat, SeatTransaction, Transaction } from '../shared/models';
import { FlowControlService } from '../digitalvenue/services/flow-control.service';
import { UserService } from '../services';

@Injectable({
    providedIn: 'root'
})
export class DemoUserService {
    userLogged = {
        username: '1001031',
        account_name: 'The MMC Company',
        first_name: 'Demo',
        last_name: 'MMC',
        email: 'demo@3ddigitalvenue.com',
        sales_rep: { first_name: 'Demo', last_name: '3DDV', email: 'sales@3ddigitalvenue.com', phone: '773-269-5293' },
        plans: [{
            id: 4,
            appointment_date_from: '2023-03-20T16:09:00Z',
            appointment_date_to: '2040-03-26T07:59:00Z',
            addons_allowed: 8,
            plan: { id: 3, name: 'Full Share Premiers', description: 'Full Share Premiers', tier: 1 },
            customer_plan_seats: [{
                id: 'S_PR13-6-9',
                section: 'PR13',
                seat_row: '6',
                seat: '9',
                price_type_name: 'F',
                price: 28020.0,
                price_code_name: null
            }, {
                id: 'S_PR13-6-10',
                section: 'PR13',
                seat_row: '6',
                seat: '10',
                price_type_name: 'F',
                price: 28020.0,
                price_code_name: null
            }, {
                id: 'S_PR13-6-11',
                section: 'PR13',
                seat_row: '6',
                seat: '11',
                price_type_name: 'F',
                price: 28020.0,
                price_code_name: null
            }, {
                id: 'S_PR13-6-12',
                section: 'PR13',
                seat_row: '6',
                seat: '12',
                price_type_name: 'F',
                price: 28020.0,
                price_code_name: null
            }, {
                id: 'S_Lot C-GA-1004',
                section: 'Lot C',
                seat_row: 'GA',
                seat: '901',
                price_type_name: 'Parking Lot C',
                price: 0.0,
                price_code_name: null
            }, {
                id: 'S_Lot C-GA-1005',
                section: 'Lot C',
                seat_row: 'GA',
                seat: '902',
                price_type_name: 'Parking Lot C',
                price: 0.0,
                price_code_name: null
            },
                {
                    id: 'S_Lexus Club-GA-1005',
                    section: 'Lexus Club',
                    seat_row: 'GA4',
                    seat: '903',
                    price_type_name: 'Lexus club',
                    price: 0.0,
                    price_code_name: null
                }],
            transaction: null,
            plan_change_availability: [],
            already_paid: null
        }],
        phone: null,
        csrf: '1bzcckyrGyf1qMOvS9FvWhYQcJ950RX61E64XSAQI9dvNylYABNN9S9PEWEwvaXY',
    };
    transactionBase: Transaction = {
        id: 774,
        status: 'PH',
        master: true,
        seat_transactions: [],
        created: '2024-02-03T17:14:22.412405Z',
        updated: '2024-02-03T17:14:22.412441Z',
        related: null,
    };
    transactionHold: Transaction;
    logged = true;

    constructor(private flowControlService: FlowControlService, private userService: UserService) {
    }

    setCustomerPlanHoldTransaction(customerPlanId) {
        // @ts-ignore
        // get customer plan
        const customerPlanTarget = this.userService.getCustomerPlanById(this.flowControlService.currentPlan);
        // const customerPlanTarget: CustomerPlan = this.userLogged.plans.find(customerPlan => customerPlan.id === customerPlanId);
        // copy base transaction
        this.transactionHold = JSON.parse(JSON.stringify(this.transactionBase));
        // created and updated now
        this.transactionHold.created = new Date().toISOString();
        this.transactionHold.updated = new Date().toISOString();
        // get kept seats and create keep seat transactions
        const keptSeats = customerPlanTarget.customer_plan_seats.filter(seat => this.flowControlService.seatsToKeep.includes(seat.id));
        this.transactionHold.seat_transactions.push(...this.createSeatTransactionsFromSeats(keptSeats, 'keep'));
        // get removed seats and create keep seat transactions
        const removedSeats = customerPlanTarget.customer_plan_seats.filter(seat => this.flowControlService.seatsToDiscard.includes(seat.id));
        this.transactionHold.seat_transactions.push(...this.createSeatTransactionsFromSeats(removedSeats, 'remove'));
        // get added seats and create add seat transaction
        this.transactionHold.seat_transactions.push(
            ...this.createSeatTransactionsFromSeats(Object.values(this.flowControlService.Basket), 'add'));
        this.transactionHold.seat_transactions.push(
            ...this.createExtraTransaction(this.flowControlService.extraBasket, 'add'));
        // @ts-ignore
        customerPlanTarget.transaction = this.transactionHold;
    }

    setTransactionOk() {
        this.transactionHold.status = 'OK';
    }

    cancelTransaction() {
        const customerPlan = this.userService.getCustomerPlanById(this.flowControlService.currentPlan);
        customerPlan.transaction = null;
    }

    getLoggedUser() {
        if (this.logged) {
            return this.userLogged;
        } else {
            return { id: -1, csrf: 'jd9SiC810BRSMYrgi82Os4Yg3KXP6Nxkz5zbvW4DPibEsMk9rQi6QPkxI74ceP7J' };
        }
    }

    setLogged() {
        this.logged = true;
    }

    private createSeatTransactionsFromSeats(seatsInfo: Seat[], action): SeatTransaction[] {
        const seatTransactionsParsed = [];
        for (const seatInfo of seatsInfo) {
            seatTransactionsParsed.push({
                seat: seatInfo.id,
                action,
                price: seatInfo.price
            });
        }
        return seatTransactionsParsed;
    }

    private createExtraTransaction(extras: string[], action, counter = 1) {
        const seatTransactionsParsed = [];
        let parkingCounter = counter;
        let lexusCounter = counter;
        let id = '';
        let price = 0;
        for (const seatInfo of extras) {
            if (seatInfo.includes('Lexus')) {
                id = 'S_Lexus Club-GA4-' + lexusCounter;
                price = 5000;
                lexusCounter++;
            }
            if (seatInfo.includes('Lot C')) {
                id = 'S_Lot C-GA-' + parkingCounter;
                price = 5100;
                parkingCounter++;
            }
            seatTransactionsParsed.push({
                seat: id,
                action,
                price
            });
        }
        return seatTransactionsParsed;
    }

    public removeSeatFromSeatTransactions(seats: string[]) {
        const newTransactions = [];
        for (const seatTransaction of this.transactionHold.seat_transactions) {
            if (!seats.includes(seatTransaction.seat)) {
                newTransactions.push(seatTransaction);
            }
        }
        this.transactionHold.seat_transactions = newTransactions;
    }

    public addExtrasToTransaction(extras: string[]) {
        this.transactionHold.seat_transactions.push(
            ...this.createExtraTransaction(extras, 'add', 7));
    }
}

import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ThemeSelectorService } from 'src/app/services/theme-selector.service';

@Component({
  selector: 'app-congratulations-modal',
  templateUrl: './congratulations-modal.component.html',
  styleUrls: ['./congratulations-modal.component.scss']
})
export class CongratulationsModalComponent implements OnInit {

  title: string;
  message: string;
  isDarkTheme: boolean;

  constructor(public bsModalRef: BsModalRef,
              private themeSelector: ThemeSelectorService) {
    this.title = 'Congratulations!';
  }

  createElementFromHTML(htmlString) {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes
    return div.firstChild;
  }

  ngOnInit() {
    this.message = `Your transaction has been confirmed successfully and new seats are now reserved for you. You will receive a confirmation email within 24 hours.`;

    const fwTemplate = `<div class="pyro">
      <div class="before"></div>
      <div class="after"></div>
    </div>`;
    const modalElement = document.getElementsByClassName('modal')[0];
    modalElement.insertBefore(this.createElementFromHTML(fwTemplate), modalElement.firstChild);
  }

  /**
   * Hides the modal
   */
  decline(): void {
    this.bsModalRef.hide();
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

}

import { Component, OnInit, Input, Inject } from '@angular/core';
import { APP_CONFIG } from '../digitalvenue.configuration';
import { FlowControlService } from '../services/flow-control.service';
import { UserService } from 'src/app/services/user.service';
import { Subject } from 'rxjs';
import { CustomerPlan } from 'src/app/shared/models';
import { DigitalvenueConfiguration } from '../digitalvenue-configuration.model';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { APIConnectionService } from '../services/api-connection.service';
import { DjangoSessionAuthenticationService, ErrorJsonService } from 'src/app/services';
import { MessagingService } from 'src/app/shared/modals/messaging.service';
import { IsolatedSeatsService } from '../services/isolated-seats.service';
import { DVMService } from '../services/dvm.service';
import { IsolatedSeatsApiService } from '../services/isolated-seats-api.service';
import * as globals from '../globals';

@Component({
  selector: 'app-bottom-interface',
  templateUrl: './bottom-interface.component.html',
  styleUrls: ['./bottom-interface.component.scss']
})
export class BottomInterfaceComponent implements OnInit {

  @Input() isSelectionAllowed;
  @Input() discardedSeats: object;
  @Input() selectedSeats: object[];
  keptSeats: object[];
  lastSelectionCount = 0;
  currentInternalBalance = 0;
  currentBalance = 0;
  timerObservable = new Subject<string>();
  timer;
  customerPlan: CustomerPlan;
  globals = globals;

  public relocateView() {
    if (Object.keys(this.discardedSeats).length > 0) {
      new Promise((s, r) => {
        const keepHash = {};
        for (const seat of this.customerPlan.customer_plan_seats) {
          for (const seatid of Object.keys(this.discardedSeats)) {
            if (seatid === seat['id']) {
              keepHash[seatid] = false;
            }
          }
          if (keepHash[seat.id] == null) {
            this.flowControl.seatsToKeep.push(seat.id);
          }
        }
        this.flowControl.relocationSeatsCount = Object.keys(this.discardedSeats).length;
        for (const discardedSeat of Object.keys(this.discardedSeats)) {
          this.flowControl.seatsToDiscard.push(discardedSeat);
        }
        this.flowControl.Basket = {};
        s(null);
      }).then(() => {
        if (this.flowControl.upgradePlan) {
          this.router.navigate(['/digitalvenue/seatselection',
            this.flowControl.currentPlan, this.flowControl.upgradePlan]);
        } else {
          this.router.navigate(['/digitalvenue/seatselection',
            this.flowControl.currentPlan]);
        }
      });
    } else if (this.flowControl.maxAddons > 0) {
      const addonTitle = 'Continue?';
      const addonMessage = `You have not selected any seats from the previous season to relocate.
                            Do you want to continue to the selection screen and purchase new additional seats?`;
      const acceptBtnName = 'Just add new seats';
      const closeBtnName = 'Close';
      this.messageService.info(addonTitle, addonMessage, acceptBtnName, () => {
        const customerPlan = this.userService.getCustomerPlanById(this.flowControl.currentPlan);
        this.flowControl.justAddonsAllowed = true;
        for (const seat of customerPlan.customer_plan_seats) {
          this.flowControl.seatsToKeep.push(seat.id);
        }
        if (this.flowControl.upgradePlan) {
          this.router.navigate(['/digitalvenue/seatselection',
            this.flowControl.currentPlan, this.flowControl.upgradePlan], { state: { data: [] } });
        } else {
          this.router.navigate(['/digitalvenue/seatselection',
            this.flowControl.currentPlan], { state: { data: [] } });
        }
      }, closeBtnName);
    } else {
      this.modals.errorModal('Please, select at least one seat to relocate.');
    }
  }

  get isCheckoutAvailable(): boolean {
    if (this.selectedSeats != null && this.discardedSeats != null) {
      return this.internalBalance > -1 && this.selectedSeats.length > 0;
      // return this.internalBalance >= 0 && this.selectedSeats.length >= Object.keys(this.discardedSeats).length;
      // return this.internalBalance > -1;
    } else {
      return false;
    }
  }

  get isContinueAvailable(): boolean {
    return (Object.keys(this.discardedSeats).length > 0 || this.flowControl.maxAddons > 0);
  }

  get isPulseEnabled(): boolean {
    return (Object.keys(this.discardedSeats).length > 0) || this.flowControl.upgradePlan;
  }

  get getTimer() {
    return this.timer;
  }

  hasSelectionChanged() {
    if (this.lastSelectionCount !== this.selectedSeats.length) {
      this.lastSelectionCount = this.selectedSeats.length;
      this.updateInternalBalance();
      this.updateBalance();
      return true;
    }
    return false;
  }

  updateInternalBalance() {
    let balance = 0;
    let countDiscarded = 0;
    // tslint:disable-next-line: forin
    for (const id in this.discardedSeats) {
      countDiscarded++;
      balance -= this.discardedSeats[id].price;
    }
    let countSelected = 0;
    // tslint:disable-next-line: forin
    for (const id in this.selectedSeats) {
      countSelected++;
      if (this.selectedSeats[id]['price'].price) {
        balance += this.selectedSeats[id]['price'].price;
      } else {
        balance += this.selectedSeats[id]['price'];
      }
    }
    this.currentInternalBalance = balance;
  }

  updateBalance() {
    let balance = 0;
    // tslint:disable-next-line: forin
    for (const id in this.selectedSeats) {
      if (this.selectedSeats[id]['price'].price) {
        balance += this.selectedSeats[id]['price'].price;
      } else {
        balance += this.selectedSeats[id]['price'];
      }
    }
    // tslint:disable-next-line: forin
    for (const id in this.keptSeats) {
      if (this.keptSeats[id]['price'].price) {
        balance += this.keptSeats[id]['price'].price;
      } else {
        balance += this.keptSeats[id]['price'];
      }
    }
    this.currentBalance = balance;
  }

  get internalBalance() {
    this.hasSelectionChanged();
    return this.currentInternalBalance;
  }

  get totalBalance() {
    this.hasSelectionChanged();
    return this.currentBalance;
  }

  constructor(@Inject(APP_CONFIG) public dvConfig: DigitalvenueConfiguration,
              private modals: ModalsService,
              private router: Router,
              public flowControl: FlowControlService,
              private userService: UserService,
              private connectionService: APIConnectionService,
              private authService: DjangoSessionAuthenticationService,
              private errorCodeService: ErrorJsonService,
              private messageService: MessagingService,
              private isolationService: IsolatedSeatsService,
              private isolatedSeatsApiService: IsolatedSeatsApiService) { }

  /*
    TIMER LOGIC - TO DO: CREATE A TIMER SERVICE SHARED WITH LANDING
  */
  isRelocationAvailable(date: string) {
    const appointmentTimestamp = new Date(date).getTime();
    const now = new Date().getTime();
    return (now >= appointmentTimestamp) ? true : false;
  }

  time2string(delta) {
    // Days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;
    // Hours
    const hoursNum = Math.floor(delta / 3600) % 24;
    const hours = (hoursNum < 10 ? '0' : '') + hoursNum;
    delta -= hoursNum * 3600;
    // Minutes
    const minutesNum = Math.floor(delta / 60) % 60;
    const minutes = (minutesNum < 10 ? '0' : '') + minutesNum;
    delta -= minutesNum * 60;
    // Seconds
    const secondsNum = Math.floor(delta % 60);
    const seconds = (secondsNum < 10 ? '0' : '') + secondsNum;
    return days + 'd ' + hours + ':' + minutes + ':' + seconds;
  }

  setTimer() {
    const date = this.customerPlan.appointment_date_from;
    const appointmentTimestamp = new Date(date).getTime();
    const now = new Date().getTime();
    const diff = Math.abs(appointmentTimestamp - now) / 1000;
    const timeString = this.time2string(diff);
    this.timerObservable.next(timeString);
  }

  setTimerInterval() {
    setInterval(() => {
      this.setTimer();
    }, 1000);
  }

  ngOnInit() {
    this.customerPlan = this.userService.getCustomerPlanById(this.flowControl.currentPlan);
    this.timerObservable.subscribe(
      timer => {
        this.timer = timer;
      }
    );
    this.setTimer();
    this.setTimerInterval();
    this.keptSeats = this.calculateKeptSeats();
    this.updateBalance();
  }

  calculateKeptSeats() {
    const customerPlanSeats = this.customerPlan.customer_plan_seats;
    const discardedSeatsKeys = Object.keys(this.discardedSeats);
    const keptSeats = [];
    customerPlanSeats.forEach(customerPlanSeat => {
      let isDiscarded = false;
      discardedSeatsKeys.forEach(discardedSeat => {
        if (discardedSeat === customerPlanSeat.id) {
          isDiscarded = true;
        }
      });
      if (!isDiscarded) {
        keptSeats.push(customerPlanSeat);
      }
    });
    return keptSeats;
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewChecked() {
  }

  public goCheckout(): void {

    // checks if the internal balance is positive
    if (this.internalBalance >= 0) {
      // splits the general admissions to get only the section and override it's position array
      const basket = Object.keys(this.flowControl.Basket);
      console.log(basket);
      for (let i = 0; i < basket.length; i++) {
        if (basket[i].split('-')[1] === 'GA') {
          basket[i] = basket[i].split('-')[0];
        }
      }
      // concat the current basket and the seats to keep
      const transactionSeats = basket.concat(this.flowControl.seatsToKeep);
      // checks if there is any isoalted seat
      this.connectionService.checkIsolatedSeats(this.customerPlan.id, transactionSeats).subscribe(
        response => {
          const isolatedSeats = response.produce_isolation;
          // if lenght it's 0 > OK, no isolated seat
          // if lenght it's != > KO, there are isolated seats
          if (isolatedSeats.length === 0) {
            // start with the transaction
            this.modals.extraModal();
            // this.createHoldTransaction(this.customerPlan);
          } else {
            this.isolatedSeatsApiService.setPendingSeats(isolatedSeats);
            this.isolatedSeatsApiService.printIsolatedSeats(isolatedSeats);
            for (let i = 0; i < isolatedSeats.length; i++) {
              isolatedSeats[i] = ' ' + isolatedSeats[i].split('S_')[1];
            }
            this.modals.errorModal(`The selected seats are leaving isolated seats: ${isolatedSeats}`);
          }
        }
      );
      // this.createHoldTransaction(this.customerPlan);
    }

  }


  /**
   * Creates the transaction in status hold ( PH ) and redirects to the checkout page,
   * if error displays an error.
   * @param customerPlan to get the selected seats
   */
  createHoldTransaction(customerPlan: CustomerPlan): void {
    const basket = Object.keys(this.flowControl.Basket);
    for (let i = 0; i < basket.length; i++) {
      if (basket[i].split('-')[1] === 'GA') {
        basket[i] = basket[i].split('-')[0];
      }
    }
    const transactionSeats = basket.concat(this.flowControl.seatsToKeep);
    this.connectionService.createHoldTransaction(this.flowControl.currentPlan, transactionSeats, this.flowControl.upgradePlan).subscribe(
      success => {
        this.authService.updateUserData().subscribe(
          userData => {
            const transaction = this.userService.getTransactionByPlanId(this.flowControl.currentPlan);
            // if all ok, redirects to the checkout page with the currentPlan
            const extraPromise = new Promise(
              (success, reject) => {
                // this.modals.extraModal(success, reject);
              }
            ).then(value => {
              this.editTransactionSeats(transaction.id);
            }).catch(value => {
              if (transaction.status === 'PH') {
                this.router.navigate(['/checkout', this.customerPlan.id]);
              }
            });
          },
          error => {
            // If error, shows a modal with the error code
            this.errorCodeService.getErrorByCode(1004).subscribe(
              errorText => {
                // displays an error modal
                this.modals.errorModal(errorText);
              }
            );
          }
        );
      },
      error => {
        const errorMessage = error.error;
        if (errorMessage.code === 'TCRRS03') {
          // this.isolationService.checkPendingIsolatedSeats();
          this.isolatedSeatsApiService.setPendingSeats(errorMessage.seats);
          this.isolatedSeatsApiService.printIsolatedSeats(errorMessage.seats);
          for (let i = 0; i < errorMessage.seats.length; i++) {
            errorMessage.seats[i] = errorMessage.seats[i].split('S_')[1];
          }
          this.modals.errorModal(`The selected seats are leaving isolated seats: ${errorMessage.seats}`);
        } else {
          // If error, shows a modal with the error code
          this.errorCodeService.getErrorByCode(1005).subscribe(
            errorText => {
              // displays an error modal
              this.modals.errorModal(errorText);
            }
          );
        }
      }
    );
  }

  /**
   * Edits the transaction in status hold ( PH ) and redirects to the checkout page,
   * if error displays an error.
   */
  editTransactionSeats(transactionId: number): void {
    this.userService.editTransactionSeats(transactionId, this.flowControl.extraBasket, 'add').subscribe(
      response => {
        this.authService.updateUserData().subscribe(
          response => {
            const transaction = this.userService.getTransactionByPlanId(this.flowControl.currentPlan);
            this.router.navigate(['/checkout', this.customerPlan.id]);
          },
          error => {
            // If error, shows a modal with the error code
            this.errorCodeService.getErrorByCode(1004).subscribe(
              errorText => {
                // displays an error modal
                this.modals.errorModal(errorText);
              }
            );
          }
        );
      },
      error => {
      }
    );
  }

}

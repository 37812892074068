import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DemoAvailabilityService {

  constructor(private http: HttpClient) {
  }

  private getDemoData(filename): Observable<any> {
    return this.http.get(`../../assets/demo/${filename}.json`);
  }

  public returnFakeData(url: string): Observable<any> {
    let filename = 'generalAvailability';
    if (url.includes('S_')) {
      const urlSplitted = url.split('/');
      filename = urlSplitted[urlSplitted.length - 2];
    }
    return this.getDemoData(filename);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ErrorJsonService {
  json: any;
  constructor(private http: HttpClient) {
    // Subscribe to the observable to get the JSON
    this.getJSON().subscribe(
      data => {
        this.json = data;
      }
    );
  }

  /**
   * Observable that returns the JSON file with all error codes
   */
  private getJSON(): Observable<any> {
    return this.http.get('../../assets/json/error-code.json');
  }

  /**
   * Returns the message of the JSON by code id
   * @param code to returns the message
   */
  public getErrorByCode(code: number) {
    return this.getJSON().pipe(
      map((data) => {
        return data[code.toString()];
      })
    );
  }

}

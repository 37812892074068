import { Component, OnInit, Input, OnDestroy, Inject } from '@angular/core';
import { ThemeSelectorService } from 'src/app/services';
import { BsModalRef } from 'ngx-bootstrap';
import { DVMService } from 'src/app/digitalvenue/services/dvm.service';
import { DVM_CONFIG } from 'src/app/digitalvenue/digitalvenue.configuration';
import { NavigationHandler } from 'src/app/digitalvenue/handlers/navigation.handler';
import { FlowControlService } from 'src/app/digitalvenue/services/flow-control.service';

@Component({
  selector: 'app-pano-view-modal',
  templateUrl: './pano-view-modal.component.html',
  styleUrls: ['./pano-view-modal.component.scss']
})
export class PanoViewModalComponent implements OnInit{

  @Input() seatId: string;
  parentModalHeight: number;
  isDarkTheme: boolean;
  viewer3dModal;

  constructor(@Inject(DVM_CONFIG) private DVMConfig,
              public bsModalRef: BsModalRef,
              private themeSelector: ThemeSelectorService,
              private flowControl: FlowControlService,
              private dvmService: DVMService) { }

  ngOnInit() {
    if (!this.viewer3dModal) {
      this.initializeDVM(this.DVMConfig).then(() => {
        this.open3dView();
      });
    } else {
      this.open3dView();
    }
  }

  /**
   * Load the pano 360 view into the modal
   */
  open3dView(view = null) {
    // const load3DView = () => {
      let venue_id = this.DVMConfig.venue_id;
      if (this.flowControl.currentVenue) {
        venue_id = this.flowControl.getCurrentMap();
      }
      const objView = { venue_id, view_id: this.seatId };
      if (view) {
        objView.view_id = view;
      }
      this.viewer3dModal.loadView3d(objView)
        .then((resolve) => {
          // document.getElementById('main-interface').style.height = '500px';
          if (this.isMobile() || window.innerWidth < 1200) {
            this.viewer3dModal.flags.fixed_aspect_ratio = false;
            document.getElementById('viewer3d-container-modal').style.height = this.parentModalHeight + 'px';
          }
          this.viewer3dModal.open();
        })
        .catch((err) => {
          console.error(err);
        });
    // };

    // if (!this.dvmService.viewer3d) {
    //   const viewer3dConfig = JSON.parse(JSON.stringify(this.DVMConfig));
    //   viewer3dConfig.container = 'viewer3d-container-modal';
    //   DVM.loadModule('3d_viewer', viewer3dConfig)
    //         .then((viewer3d) => {
    //           this.dvmService.viewer3d = viewer3d;
    //           load3DView();
    //         })
    //         .catch((err) => {
    //             console.error(err);
    //         });
    // } else {
    //   load3DView();
    // }

  }

  /**
   * Hides the modal
   */
  close(): void {
    this.bsModalRef.hide();
  }

  /**
   * Checks if the navigator it's a mobile
   * @returns boolean
   */
  isMobile(): boolean {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

  initializeDVM(config) {
    const viewer3dConfig = JSON.parse(JSON.stringify(config));
    viewer3dConfig.container = 'viewer3d-container-modal';
    viewer3dConfig.plugins = ['navigation'];
    return DVM.loadModule('3d_viewer', viewer3dConfig)
      .then((viewer3d) => {
        this.viewer3dModal = viewer3d;
        this.viewer3dModal.subscribe('click_navigation_node', this.navigationHandler.bind(this));
      })
      .catch((err) => {
        console.error(err);
      });
  }

  navigationHandler(obj) {
      this.open3dView(obj.navigation.view_id);
  }


}

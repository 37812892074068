import { Component, OnInit } from '@angular/core';
import { ThemeSelectorService, LocalStorageService, UserService } from './services';
import { Router, NavigationEnd } from '@angular/router';
import { User } from './shared/models';
declare let gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  user: User;
  title = 'staplescenter-ticket-management-portal';

  constructor(private themeSelector: ThemeSelectorService,
              private userService: UserService,
              private storageService: LocalStorageService,
              private router: Router) {
                // this.router.events.subscribe(event => {
                //   if (event instanceof NavigationEnd) {
                //       gtag('config', 'UA-74364748-15',
                //             {
                //               page_path: event.urlAfterRedirects
                //             }
                //            );
                //    }
                // });
              }

  ngOnInit() {
    if (this.storageService.storageAvailable('localStorage')) {
      this.storageService.isStorageAvailable = true;
    }
    this.userService.user$.subscribe(
      user => {
        this.user = user;
      }
    );
  }

  isAdmin() {
    if (this.user) {
      return this.user.hasOwnProperty('admin_takeover');
    }
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }
}


import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class InitializerService {

  constructor(private userService: UserService) { }

  init() {
    return new Promise<void>((resolve, reject) => {
        this.userService.getSeasons().subscribe(
            response => {
                let currentSeason;
                Object.entries(response).forEach(
                    ([key, value]) => {
                        if (value['openhouse'] === environment.isOpenhouse && value['latest']) {
                            currentSeason = key;
                        }
                    }
                );
                console.log("Current Season Detected: ", currentSeason);
                this.userService.currentSeason = currentSeason;
                if (this.userService.currentSeason) {
                    resolve();
                } else {
                    console.error('No season detected for this flow - Is Open House: ', environment.isOpenhouse);
                    reject();
                }
            }
        );
    });
  }

}

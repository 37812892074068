import { Component, Inject, Input, OnInit, AfterViewInit } from '@angular/core';
import { DjangoSessionAuthenticationService, UserService } from 'src/app/services';
import { Router, ParamMap, Params, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { ClickHandler } from '../handlers/click.handler';
import { LoadHandler } from '../handlers/load.handler';
import { DVMService } from '../services/dvm.service';
import { ColoringService } from '../services/coloring.service';
import { FlowControlService } from '../services/flow-control.service';
import { APP_CONFIG } from 'src/app/configuration/ticket-management-portal-configuration';
import { DigitalvenueConfiguration } from '../digitalvenue-configuration.model';
import { PopoverCreateHandler } from '../handlers/popover-create.handler';
import { PopoverDestroyHandler } from '../handlers/popover-destroy.handler';
import { NavigationHandler } from '../handlers/navigation.handler';

@Component({
  selector: 'app-preview-landing',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
  providers: [
    ClickHandler,
    LoadHandler,
    PopoverCreateHandler,
    PopoverDestroyHandler,
    NavigationHandler
  ]
})
export class PreviewComponent implements OnInit, AfterViewInit {

  isSelectionAllowed: boolean;
  discardedSeats: object;
  currentBasket: object[];
  planId: number;
  _upgradePlan: number;

  get viewer() {
    return this.dvmService.viewer;
  }

  @Input() set upgrade(upgrade) {
    this._upgradePlan = parseInt(upgrade, 10);
  }

  constructor(private auth: DjangoSessionAuthenticationService,
              private route: ActivatedRoute,
              private dvmService: DVMService,
              private coloring: ColoringService,
              private flowControl: FlowControlService,
              private userService: UserService,
              private clickHandler: ClickHandler,
              private loadHandler: LoadHandler,
              private popoverCreateHandler: PopoverCreateHandler,
              private popoverDestroyHandler: PopoverDestroyHandler,
              private navigationHandler: NavigationHandler,
              private routing: Router,
              @Inject(APP_CONFIG) private dvConfig: DigitalvenueConfiguration) {}

  ngAfterViewInit() {
    // this.coloring.applyInterfaceColors();
  }

  ngOnInit() {
    this.userService.sendUrl(this.routing.url);
    this.planId = parseInt(this.route.snapshot.paramMap.get('planId'), 10);
    this.flowControl.discardedSeats = {};
    this.discardedSeats = this.flowControl.discardedSeats;
    // this.isSelectionAllowed = this.state.$current.data.isSelectionAllowed;
    this.isSelectionAllowed = false;
    this.userService.setSelectionAllowed(false);
    this.flowControl.init(this.planId);
    if (this._upgradePlan && this._upgradePlan !== this.planId) {
      this.flowControl.upgradePlan = this._upgradePlan;
      // Preview Upgrade Modal
      // this.modals.upgradeModal();
    } else {
      this.flowControl.upgradePlan = null;
    }
    const customerPlans = this.userService.getCustomerPlans();
    const plansInit = (plans) => {
      for (const plan of plans) {
        if (plan.id === this.planId) {
          if (plan.transaction != null) {
            // PENDING:
            // switch (plan.transaction.status) {
            //   case 'PH':
            //     this.state.go('common.checkout', {planId: this.flowControl.currentPlan});
            //     break;
            //   case 'PR':
            //     this.state.go('common.summary', {planId: this.flowControl.currentPlan});
            //     break;
            //   case 'OK':
            //     this.state.go('common.summary', {planId: this.flowControl.currentPlan});
            //     break;
            //   default:
            //     break;
            // }
          }
          this.flowControl.maxAddons = plan.addons_allowed;

          const currentBasket = [];
          plan['customer_plan_seats'].forEach(seat => {
            if (!(seat.price === 0 ||
              seat.section === 'Lexus Club' ||
              seat.section === 'Lot C' ||
              seat.section === 'Lot 1' ||
              seat.section === 'Lot E' ||
              seat.section === 'Lot W')) {
              currentBasket.push(seat);
            }
          });
          this.currentBasket = currentBasket;
        }
      }
    };
    if (!customerPlans || customerPlans.length === 0) {
      this.auth.getUserLogged$().subscribe(
        userData => {
          this.userService.setUser(userData);
          plansInit(userData['plans']);
        },
        error => {
          // this.modals.errorModal(error.message);
          console.error(error);
        }
      );
    } else {
      plansInit(customerPlans);
    }

    if (this.userService.getCustomerPlanById(this.planId).plan.tier != 1) {
      this.routing.navigate(['/home']);
    } else {
      this.dvmService.restartDVM();
    }

  }

}

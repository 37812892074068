import { Component, OnInit, Inject } from '@angular/core';
import { APP_CONFIG } from '../configuration/ticket-management-portal-configuration';
import { TicketManagementPortalConfiguration } from '../configuration/ticket-management-portal-configuration.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(@Inject(APP_CONFIG) public app_config: TicketManagementPortalConfiguration) { }

  ngOnInit() {
  }

}

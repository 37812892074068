import { HTTP_INTERCEPTORS, HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { DemoAvailabilityService } from './demo-availability.service';
import { DemoUserService } from './demo-user.service';

@Injectable()
export class DemoMockBackendInterceptor implements HttpInterceptor {

  constructor(private http: HttpClient, private demoAvailabilityService: DemoAvailabilityService,
              private demoUserService: DemoUserService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.handleRequests(req, next);
  }

  handleRequests(req: HttpRequest<any>, next: HttpHandler): any {
    const { url, method, body } = req;
    console.log(url);
    if (url.includes('season-list')) {
      return this.fakeResponse({'2023-SAS': {latest: true, openhouse: false}, '2023-OH': {latest: true, openhouse: true}});
    }
    // only environment urls
    if (url.includes('logged')) {
      return this.fakeResponse(this.demoUserService.getLoggedUser());
    }
    if (url.includes('login')) {
      this.demoUserService.setLogged();
      return this.fakeResponse(this.demoUserService.getLoggedUser());
    }
    if (url.includes('availability')) {
      return this.demoAvailabilityService.returnFakeData(url).pipe(
        switchMap(response => {
          return this.fakeResponse(response);
        })
      );
    }
    if (url.includes('seat_transaction')) {
      if (method === 'DELETE') {
        this.demoUserService.removeSeatFromSeatTransactions(body.seats);
      }
      if (method === 'PUT') {
        this.demoUserService.addExtrasToTransaction(body.seats);
      }
      return this.fakeResponse();
    }
    if (url.includes('transaction') && !url.includes('seat_transaction')) {
      if (method === 'POST') {
        this.demoUserService.setCustomerPlanHoldTransaction(body.customer_plan_id);
      } else if (method === 'PUT') {
        this.demoUserService.setTransactionOk();
      } else if (method === 'DELETE') {
        this.demoUserService.cancelTransaction();
      }
      return this.fakeResponse();
    }
    if (url.includes('check_isolation')) {
      return this.fakeResponse({produce_isolation: []});
    }
    // if there is not any matches return default request.
    return next.handle(req);
  }

  fakeResponse(body?) {
    return of(new HttpResponse({ status: 200, body })).pipe(delay(200));
  }
}
export let demoMockBackendInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: DemoMockBackendInterceptor,
  multi: true,
};

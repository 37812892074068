import { Injectable, Inject } from '@angular/core';
import { Seat } from 'src/app/shared/models';
import { DigitalvenueConfiguration } from '../digitalvenue-configuration.model';
import { APP_CONFIG } from '../digitalvenue.configuration';

@Injectable({
  providedIn: 'root'
})
export class GaService {
  public gaHash = {};
  private generalAdmissionSections = {};

  constructor(@Inject(APP_CONFIG) private appConfig: DigitalvenueConfiguration) {
    for (const gaSection of this.appConfig.generalAdmissionAreas) {
      this.generalAdmissionSections[gaSection] = true;
    }
  }

  set gaHashDiscard(seats: Array<Seat>) {
    for (const seat of seats) {
      this.gaHash[seat.id.split('-')[2]] = false;
    }
  }

  set gaHashInsert(seats: Array<Seat>) {
    for (const seat of seats) {
      this.gaHash[seat.id.split('-')[2]] = true;
    }
  }

  getNextGaIndex(): string {
    for (const index of Object.keys(this.gaHash)) {
      if (this.gaHash[index] === false) {
        return index;
      }
    }
    return Object.keys(this.gaHash).length + 1 + '';
  }

  getGeneralAdmissionSections(): object {
    return this.generalAdmissionSections;
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { ThemeSelectorService } from '../../../services/theme-selector.service';
import { ModalsService } from 'src/app/shared/modals/modals.service';

@Component({
  selector: 'app-plan-seat-element',
  templateUrl: './plan-seat-element.component.html',
  styleUrls: ['./plan-seat-element.component.scss']
})
export class PlanSeatElementComponent implements OnInit {

  @Input() seat;
  @Input() i;
  @Input() seatId;
  @Input() elementsLength: number;
  isDarkTheme: boolean;

  constructor(private themeSelector: ThemeSelectorService,
              private modalService: ModalsService) { }

  ngOnInit() {
  }

  /**
   * Displays the 360 view modal
   */
  open360Modal() {
    this.modalService.panoViewModal(this.seatId);
    // const initialState = {
    //   animated: true,
    //   seatId: this.seatId // seatId to open the pano view
    // };

    // this.bsModalRef = this.modalService.show(PanoViewModalComponent,  { initialState, class: 'modal-lg modal-dialog-centered' });
  }

  formatPrice(price: number) {
    return price.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 2});
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

}

import { Injectable, Inject } from '@angular/core';
import { DVMService } from '../services/dvm.service';
import { GeneralHandler } from './general.handler';
import { DVM_CONFIG } from '../digitalvenue.configuration';
import { DVMConfiguration } from '../dvmconfiguration.model';
import { createPopper, Modifier, Placement } from '@popperjs/core';
import detectOverflow from '@popperjs/core/lib/utils/detectOverflow.js';
import * as $ from 'jquery';
import { FlowControlService } from '../services/flow-control.service';
import { Popover3dviewService } from '../services/popover-3dview.service';
import { PricingService } from '../services/pricing.service';

@Injectable()
export class PopoverCreateHandler extends GeneralHandler {

    tooltip: HTMLElement;
    view3DId;
    descriptionSections = {
        S_PR1 : true,
        S_PR2 : true,
        S_PR17 : true,
        S_PR18 : true
    };
    descriptionText = 'This section is typically relocated for special events purchased.';

    constructor(protected dvmService: DVMService,
                private pricing: PricingService,
                private flowControl: FlowControlService,
                private popover3dview: Popover3dviewService,
                @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration) {
        super('enter', dvmService);
    }

    protected handle(obj) {
        this.popover3dview.deleteTimer();
        if (obj.nodes.length && (obj.nodes[0].state === 'available' || obj.nodes[0].state === 'selected')) {
            const node = obj.nodes[0];
            this.popover3dview.currentView = node.id;

            // Receive tooltip HTML Element
            this.tooltip = document.querySelector('#tooltip');

            // Generate tooltip content based on node type
            let tooltipContent;
            if (node.type === 'section' && obj.instance.getMapId() === this.dvmConfig.map_id) {
                tooltipContent = this.sectionPopoverContent(node);
            } else if (node.type === 'seat') {
                let description;
                if (this.descriptionSections[node.id.split('-')[0]]) {
                    description = this.descriptionText;
                }
                tooltipContent = this.seatPopoverContent(node, description);
            }

            // Create content HTML Element, clean previous popover and append
            const tooltipContentHTML = document.createElement('div');
            tooltipContentHTML.innerHTML = tooltipContent.trim();
            const popoverBodyNode = document.querySelector('#tooltip .popover-body');
            while (popoverBodyNode.firstChild) {
                popoverBodyNode.removeChild(popoverBodyNode.firstChild);
            }
            popoverBodyNode.appendChild(tooltipContentHTML);

            // Request and place thumbnail
            this.dvmService.viewer3d.getThumbnail({ venue_id: this.dvmService.viewer.getVenueId(),
                view_id: node.id }, true).then((img) => {
                $('.ticket-selected-view-img').html('');
                $('.ticket-selected-view-img').css('background-image', 'url("' + img + '")');
            });

            // Calculate popover placement
            let placement: Placement;
            if (node.anchor.dom[1] > (document.getElementById('main-interface').offsetHeight / 2)) {
                placement = 'top';
            } else {
                placement = 'bottom';
            }

            // Initiate popover
            createPopper(node, this.tooltip, {
                placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8],
                        },
                    },
                    {
                        name: 'flip',
                        options: {
                            fallbackPlacements: [],
                        }
                    }
                ],
            });

            // Display popover
            this.tooltip.setAttribute('data-show', '');
        }
    }

    open3DView() {
        this.dvmService.load3DView(this.view3DId);
      }

    sectionPopoverContent(node) {
        const extraText = this.flowControl.getSuitesLoungesExtraText(node.id);
        const areaName = node.id.split('_')[1].split('(')[0];
        const tooltipContent =
        `<div class='ticket-selected-view'>
            <div class='ticket-selected-view-img'></div>
        </div>
        <ul class='list-unstyled ticket-selected'>
            <span class='ticket-val'>Click to view</span>
            <li style='text-align: center;'>
                <span class='ticket-lab'>Section</span>
                <span class='ticket-val'> ${areaName}</span>
            </li>
        </ul>
        <ul class='list-unstyled ticket-selected-seat'>
            <li style='display:block;'>
                <span class='ticket-description'>${extraText}</span>
            </li>
        </ul>`;

        if (Object.keys(this.flowControl.discardedSeats).length === 0 && !this.flowControl.justAddonsAllowed) {
            return tooltipContent;
        }

        const price = this.pricing.areapricing[node.id];

        // if (price) {
        //     if (price['min'] !== price['max']) {
        //         tooltipContent += '<ul class=\'list-unstyled ticket-selected\'>' +
        //             '<li style=\'display:block;\'>' +
        //             '<span class=\'ticket-lab\'>Price </span>' +
        //             '<span class=\'ticket-val\'>$' +
        //             Number.parseFloat(price['min']).toLocaleString(undefined, { maximumFractionDigits: 2 })
        //             + ' - $' +
        //             Number.parseFloat(price['max']).toLocaleString(undefined, { maximumFractionDigits: 2 })
        //             + '</span>' +
        //             '</li>' +
        //             '</ul>';
        //     } else {
        //         tooltipContent += '<ul class=\'list-unstyled ticket-selected\'>' +
        //             '<li style=\'display:block;\'>' +
        //             '<span class=\'ticket-lab\'>Price </span>' +
        //             '<span class=\'ticket-val\'>$' +
        //             Number.parseFloat(price['min']).toLocaleString(undefined, { maximumFractionDigits: 2 })
        //             + '</span>' +
        //             '</li>' +
        //             '</ul>';
        //     }
        // }
        return tooltipContent;
    }

    seatPopoverContent(node, description?) {
        const seating = node.id.split('-');
        const section = seating[0].split('_')[1].split('(')[0];
        const row = seating[1];
        const seat = seating[2];
        let tooltipContent = `<div class='ticket-selected-view'>
            <div class='ticket-selected-view-img'></div>
        </div>
        <ul class='list-unstyled ticket-selected'>
            <li>
                <span class='ticket-val'>Click to view</span>
            </li>
        </ul>
        <ul class='flex ticket-selected-seat'>
        <li style='display:block;'>
                <span class='ticket-lab'>Section</span>
                <span class='ticket-val'>` + section + `</span>
            </li>
            <li>
                <span class='ticket-lab'>Row</span>
                <span class='ticket-val'>` + row + `</span>
            </li>
            <li>
                <span class='ticket-lab'>Seat</span>
                <span class='ticket-val'>` + seat + `</span>
            </li>
        </ul>`;

        if (!(Object.keys(this.flowControl.discardedSeats).length === 0 && !this.flowControl.justAddonsAllowed)) {
            if ((Object.keys(this.flowControl.Basket).length >= Object.keys(this.flowControl.discardedSeats).length)
            || (this.pricing.seatpricing[node.id]['price_type'] !== this.pricing.maxPrice['price_type'])) {
                const price = this.pricing.getSeatPrice(node.id);
                if (price) {
                    tooltipContent += '<ul class=\'list-unstyled ticket-selected\'>' +
                        '<li style=\'display:block;\'>' +
                        '<span class=\'ticket-lab\'>New price </span> ' +
                        '<span class=\'ticket-val\'>$' +
                        Number.parseFloat(price).toLocaleString(undefined, { maximumFractionDigits: 2 })
                        + '</span>' +
                        '</li>' +
                        '</ul>';
                }
            } else {
                tooltipContent += '<ul class=\'list-unstyled ticket-selected\'>' +
                        '<li style=\'display:block;\'>' +
                        '<span class=\'ticket-lab\'>Relocation</span> ' +
                        '</li>' +
                        '</ul>';
            }
        }
        if (description) {
            tooltipContent += '<ul class=\'list-unstyled ticket-selected-seat\'>' +
            '<li style=\'display:block;\'>' +
            '<span class=\'ticket-description\'>' + description + '</span> ' +
            '</li>' +
            '</ul>';
        }
        return tooltipContent;
    }
}

import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG } from '../digitalvenue.configuration';
import { DigitalvenueConfiguration } from '../digitalvenue-configuration.model';
declare var $: any;
// import * as $ from 'jquery';
// import { ModalsService } from 'src/app/services/modals.service';

@Injectable({
    providedIn: 'root'
})

export class ParkingService {

    public parkingGeneralAvailability;
    public parkingSlots = Array.from(Array(this.dvConfig.parkingConfiguration.parkingMaxSelection), (e, i) => i + 1);
    nrSelect = false;
    public parkingSelectFlag = true;

    constructor(@Inject(APP_CONFIG) private dvConfig: DigitalvenueConfiguration) {
    }

    /* PRIVATE */

    private checkoutPostMessage() {
        window.parent.postMessage(JSON.stringify({'EventName': 'checkout'}), '*');
    }

    /* PUBLIC */

    public parkingSelect1(value: string) {
        if (value != null) {
            if (value === 'true') {
            this.parkingSelectFlag = false;
            } else if (value === 'false') {
            this.parkingSelectFlag = true;
            }
        }
    }

    public addParking(areaid: string, slots: number) {
        // this.connection.getPriceTypes().subscribe(
        //     priceTypes => {
        //         const pT = priceTypes[0]['b:key'];
        //         let count = slots;
        //         for (let i = 0; i < slots; ++i) {
        //             this.connection.addGAParkingToBasket(areaid, this.app_config).subscribe(
        //                 addGAParkingResponse => {
        //                     count--;
        //                     if (count === 0) {
        //                     this.checkoutPostMessage();
        //                     }
        //                 },
        //                 error => {
        //                     console.error(error);
        //                 }
        //             );
        //         }
        //     }
        // );
    }

}

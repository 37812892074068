import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { SummaryComponent } from './checkout/summary/summary.component';
import { FormsModule } from '@angular/forms';
import { APP_CONFIG, ApplicationConfiguration } from './configuration/ticket-management-portal-configuration';
import { FooterComponent } from './footer/footer.component';
import { DjangoSessionAuthenticationService, UserService } from './services';
import { LoginModule } from './login/login.module';
import { CheckoutModule } from './checkout/checkout.module';
import { DigitalvenueModule } from './digitalvenue/digitalvenue.module';
import { LandingModule } from './landing/landing.module';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModalModule, TooltipModule } from 'ngx-bootstrap';
import { CommonComponent } from './common/common.component';
import { AdminLoginBannerComponent } from './admin-login-banner/admin-login-banner.component';
import { AuthInterceptor } from './auth/auth-interceptor';
import { environment } from './../environments/environment';
import { InitializerService } from './services/intializer.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { demoMockBackendInterceptorProvider } from './demo/demo-mock-backend.interceptor';

export function initialize(initService: InitializerService) {
  return () => {
    return initService.init();
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SummaryComponent,
    FooterComponent,
    CommonComponent,
    AdminLoginBannerComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    LoginModule,
    CheckoutModule,
    DigitalvenueModule,
    LandingModule,
    SharedModule,
    HttpClientModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
  ],
  providers: [
    {provide: APP_INITIALIZER, useFactory: initialize, deps: [InitializerService], multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: APP_CONFIG, useValue: ApplicationConfiguration},
    DjangoSessionAuthenticationService,
    {provide: LOCALE_ID, useValue: 'en-US'},
    demoMockBackendInterceptorProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable, Inject } from '@angular/core';
import { DVMService } from '../services/dvm.service';
import { GeneralHandler } from './general.handler';
import { DVM_CONFIG } from '../digitalvenue.configuration';
import { DVMConfiguration } from '../dvmconfiguration.model';
import { General3dHandler } from './general-3d.handler';

@Injectable()
// export class NavigationHandler {
//     constructor(protected dvmService: DVMService,
//                 @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration) {
//         this.dvmService.viewer3dSubject.subscribe(
//             viewer3d => {
//                 viewer3d.subscribe('click_navigation_node', (obj) => {
//                     this.dvmService.load3DView(obj.navigation.view_id);
//                 });
//             }
//         );

//     }
// }

export class NavigationHandler extends General3dHandler {
    constructor(protected dvmService: DVMService,
                @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration) {
        super('click_navigation_node', dvmService);
    }

    protected handle(obj) {
        if (this.dvmService.isViewer3dSubjectInitialized) {
            this.dvmService.load3DView(obj.navigation.view_id);
        } else {
            this.dvmService.viewer3dSubject.subscribe(
                viewer3d => {
                    this.dvmService.load3DView(obj.navigation.view_id);
                },
                error => {
                    console.error(error);
                }
            );
        }
    }
}

import { Injectable, Inject } from '@angular/core';
import { DVMService } from '../services/dvm.service';
import { GeneralHandler } from './general.handler';
import { DVM_CONFIG } from '../digitalvenue.configuration';
import { DVMConfiguration } from '../dvmconfiguration.model';
import { APIConnectionService } from '../services/api-connection.service';
import { FlowControlService } from '../services/flow-control.service';
import { PricingService } from '../services/pricing.service';

@Injectable()
export class LoadHandler extends GeneralHandler {
    constructor(@Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
                private flowControl: FlowControlService,
                private pricing: PricingService,
                private connection: APIConnectionService,
                protected dvmService: DVMService) {
        super('end_load', dvmService);
    }

    loadBlockmapHandler(viewer) {
        viewer.setAvailability([]);
        this.connection.getPlanAvailability(this.flowControl.currentPlan, this.flowControl.upgradePlan).subscribe(
            planAvailability => {
                // tslint:disable-next-line: forin
                for (const areaId in planAvailability) {
                    this.pricing.areapricing[areaId] = {
                        min: planAvailability[areaId]['min_price'].toFixed(2),
                        max: planAvailability[areaId]['max_price'].toFixed(2)
                    };
                }
                viewer.setAvailable('section', Object.keys(planAvailability));
                viewer.setAvailable('section', Object.keys(this.flowControl.suitesHash));
                viewer.setNodesTag(Object.keys(this.flowControl.suitesHash), 'vip');
                viewer.setNodesTag(Object.keys(this.flowControl.greenSuites), 'vipGreen');
                if (!this.flowControl.upgradePlan) {
                    this.connection.getPlanSeats(this.flowControl.currentPlan).subscribe(
                        basket => {
                            const basketArray = [];
                            for (const seat of basket) {
                                basketArray.push('S_' + seat['section']);
                            }
                            viewer.setNodesTag(basketArray, 'owned');
                            viewer.setAvailable('section', basketArray);
                            // status.blockmap.moduleRef['setStatus'](basketArray, 'owned');
                        },
                        error => {
                            console.error(error.message);
                        }
                    );
                }
            },
            error => {
                console.error(error);
            });

    }

    loadSeatmapHandler(viewer) {
        viewer.setAvailability([]);
        this.connection.getPlanSectionAvailability(this.flowControl.currentPlan,
            viewer.getMapId(), this.flowControl.upgradePlan).subscribe(
                planSectionAvailability => {
                    // tslint:disable-next-line: forin
                    for (const seatId in planSectionAvailability) {
                        this.pricing.seatpricing[seatId] = {price: planSectionAvailability[seatId].price_type.prices[0].price,
                        price_type: planSectionAvailability[seatId].price_type.name};
                    }
                    viewer.setAvailable('seat', Object.keys(planSectionAvailability));

                    if (!this.flowControl.upgradePlan) {
                        this.connection.getPlanSeats(this.flowControl.currentPlan).subscribe(
                            basket => {
                                const basketArray = [];
                                for (const seat of basket) {
                                    basketArray.push(seat['id']);
                                }
                                viewer.setNodesTag(basketArray, 'owned');
                                viewer.setAvailable('seat', basketArray);
                                // viewer.select(basketArray);
                            },
                            error => {
                                console.error(error.message);
                            }
                        );
                    }
                },
                error => {
                    console.error(error);
                }
            );

    }

    protected handle(error, viewer) {
        if (error) { console.error(error); }
        viewer = viewer.instance;
        if (viewer.getMapId() === this.dvmConfig.map_id) {
            this.loadBlockmapHandler(viewer);
        } else {
            this.loadSeatmapHandler(viewer);
        }
    }
}

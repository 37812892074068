import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DjangoSessionAuthenticationService } from '../services';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  username;
  password;
  password2;
  backgroundUrl;

  token;

  requestSent = false;

  constructor(private activatedRoute: ActivatedRoute, private authService: DjangoSessionAuthenticationService, private router: Router) { }

  ngOnInit() {
      // this.backgroundUrl = `url(${this.appConfig.backgroundImageUrl})`;
      if (this.activatedRoute.snapshot.params) {
          if (this.activatedRoute.snapshot.params.t) {
              this.token = this.activatedRoute.snapshot.params.t;
          }
      }
  }

  sendEmail() {
      if (this.username) {
          this.authService.passwordReset(this.username).subscribe(
              response => {
                  console.log(response);
                  this.requestSent = true;
            },
              error => {
                  console.error(error);
            }
        );
    }
  }


  newPassword() {
      if (this.token && this.password && this.password2 && this.password === this.password2) {
          this.authService.passwordResetFinalize(this.token, this.password).subscribe(
                response => {
                    console.log(response);
                    this.requestSent = true;
                },
                error => {
                    console.error(error);
                    // this.dataService.isLoaderActive = false;
                }
            );
        }
  }

  toLogin() {
      this.router.navigate(['/login']);
  }
}

import { Injectable, Inject } from '@angular/core';
import { Seat } from 'src/app/shared/models';
import { FlowControlService } from './flow-control.service';

@Injectable({
    providedIn: 'root'
})

export class PricingService {
    // Pricing storage
    public seatpricing = {};
    public areapricing = {};
    public maxPrice: {price?: number, price_type?: string} = {};

    private seatsPricingQueue = [];
    private seatsPricingQueueBuffer = [];

    constructor(private flowControl: FlowControlService) {}

    get pricingQueueLength() {
        return this.seatsPricingQueue.length;
    }

    pricingQueueHash = {};

    init(seatsArray: Seat[]) {
        seatsArray.sort((a, b) => a.price - b.price);
        this.seatsPricingQueue = seatsArray;
    }

    setMaxPrice(seatsArray) {
        seatsArray.sort((a, b) => a.price - b.price);
        const maxPriceSeat = seatsArray.pop();
        this.maxPrice = {price: maxPriceSeat.price, price_type: maxPriceSeat.price_type_name};
    }

    getSeatPrice(seatId) {
        return this.calculatePricing(seatId, false);
    }

    setPricing(seatId) {
        return this.calculatePricing(seatId, true);
    }

    calculatePricing(seatId, setPrice) {
        // if we are upgrading the prices logic are not applied
        // the price is always market price
        if (this.flowControl.upgradePlan) {
            return this.seatpricing[seatId].price;
        }
        const selectedPriceType = this.seatpricing[seatId].price_type;
        if (this.pricingQueueLength) {
            const queueLastPriceType = this.seatsPricingQueue[this.seatsPricingQueue.length - 1].price_type_name;
            if (queueLastPriceType === selectedPriceType) {
                if (setPrice) {
                    return this.usePrice(seatId);
                } else if (this.pricingQueueHash[seatId]) {
                    return this.pricingQueueHash[seatId];
                }
                // Pending...
                return this.maxPrice['price'];
            }
        }
        if (!this.pricingQueueLength && (selectedPriceType === this.maxPrice['price_type'])
        && this.maxPrice['price'] > this.seatpricing[seatId].price) {
            return this.maxPrice['price'];
        }
        if (selectedPriceType < this.maxPrice['price_type']) {
            if (this.maxPrice['price'] > this.seatpricing[seatId].price) {
                return this.maxPrice['price'];
            }
        }
        return this.seatpricing[seatId].price;
    }

    usePrice(seatId) {
        if (this.seatsPricingQueue.length) {
            const popped = this.seatsPricingQueue.pop();
            const price = popped.price;
            this.pricingQueueHash[seatId] = price;
            this.seatsPricingQueueBuffer.push(popped);
            return price;
        }
    }

    restorePrice(seatId) {
        if (this.seatsPricingQueueBuffer.length && this.pricingQueueHash[seatId]) {
            const popped = this.seatsPricingQueueBuffer.pop();
            this.seatsPricingQueue.push(popped);
            delete this.pricingQueueHash[seatId];
        }
    }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lexus-seat-element',
  templateUrl: './lexus-seat-element.component.html',
  styleUrls: ['./lexus-seat-element.component.scss']
})
export class LexusSeatElementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

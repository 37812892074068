import { Component, OnInit, Input } from '@angular/core';
import { CustomerPlan } from 'src/app/shared/models';
import { BsModalRef } from 'ngx-bootstrap';
import { ThemeSelectorService } from 'src/app/services';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { ExtraService } from 'src/app/digitalvenue/services/extra.service';

@Component({
  selector: 'app-lexus-element',
  templateUrl: './lexus-element.component.html',
  styleUrls: ['./lexus-element.component.scss']
})
export class LexusElementComponent implements OnInit {

  @Input() lexusHash: {};
  innerWidth = window.innerWidth;
  bsModalRef: BsModalRef;
  isDarkTheme: boolean;
  debug: boolean;
  totalLexus = 0;
  totalPrice = 0;

  constructor(private themeSelector: ThemeSelectorService,
              public extraService: ExtraService,
              private modalService: ModalsService) { }

  ngOnInit() {
    this.init();
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

  private init() {
    console.log(this.lexusHash);
    Object.values(this.lexusHash).forEach(
      element => {
        console.log(element);
        this.totalPrice += element['price'];
        let lexusNumber;
        if (element['seat_row']) {
          lexusNumber = element['seat_row'].split('GA')[1];
        } else {
          lexusNumber = element['seat'].split('-')[1].split('GA')[1];
        }
        if (lexusNumber && Number.parseInt(lexusNumber, 10)) {
          this.totalLexus += Number.parseInt(lexusNumber, 10);
        }
      }
    );
    // this.totalLexus = Object.keys(this.lexusHash).length;
  }



  /**
   * Check if the browser it's mobile
   * @returns true in case of mobile, othewise false
   */
  isMobile(): boolean {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  }

  addLexus(): void {
    // this.modalService.lexusModal();
  }

}

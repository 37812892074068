import { Injectable, Inject } from '@angular/core';
import { DVMService } from '../services/dvm.service';
import { GeneralHandler } from './general.handler';
import { DVM_CONFIG } from '../digitalvenue.configuration';
import { DVMConfiguration } from '../dvmconfiguration.model';
import { FlowControlService } from '../services/flow-control.service';

@Injectable()
export class ClickHandler extends GeneralHandler {
    constructor(protected dvmService: DVMService,
                private flowControl: FlowControlService,
                @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration) {
        super('click', dvmService);
    }

    sectionClickHandler(node, viewer) {
        if (node.state === 'available') {
            const loadOptions = JSON.parse(JSON.stringify(this.dvmConfig));
            loadOptions.map_id = node.id;
            loadOptions.venue_id = this.dvmService.viewer.getVenueId();
            this.dvmService.loadMap(loadOptions);
        }
    }

    seatClickHandler(node, viewer) {
        if (node.state === 'available') {
            this.dvmService.load3DView(node.id);
        }
    }

    protected handle(obj) {
        // Close popovers
        const tooltip: HTMLElement = document.querySelector('#tooltip');
        tooltip.removeAttribute('data-show');

        // Handle
        const viewer = obj.instance;
        const nodes = obj.nodes;
        if (nodes.length) {
            if (nodes[0].type === 'section') {
                if (this.flowControl.suitesHash[nodes[0].id]) {
                    this.dvmService.load3DView(nodes[0].id);
                } else {
                    this.sectionClickHandler(nodes[0], viewer);
                }
            } else if (nodes[0].type === 'seat') {
                this.seatClickHandler(nodes[0], viewer);
            }
        }
    }
}

import { Component, OnInit, Inject, Input } from '@angular/core';
declare var $: any;
// import * as $ from 'jquery';
import { FlowControlService } from '../services/flow-control.service';
import { APP_CONFIG } from '../digitalvenue.configuration';
import { DigitalvenueConfiguration } from '../digitalvenue-configuration.model';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @Input() status;
  currentFocus = 0;
  blockhash;

  constructor(private flowControl: FlowControlService,
              @Inject(APP_CONFIG) public app_config: DigitalvenueConfiguration) { }


  triggerSearchInput() {
    if ($('.form-group').hasClass('showClass')) {
      this.hideSearchInput();
    } else {
      this.showSearchInput();
      $('#search-input').focus();
    }
  }

  showSearchInput() {
    $('#search-input').removeClass('hidden');
    $('.form-group').addClass('showClass');
    $('#search-button').addClass('search-icon-focus');
    $('#search-list').removeClass('hidden');
  }

  hideSearchInput() {
    const that = this;
    $('.form-group').removeClass('showClass');
    $('#search-button').removeClass('search-icon-focus');
    setTimeout(function() {
      if (!$('#search-list').is(':hover')) {
        $('#search-input').val('');
        $('#search-input').addClass('hidden');
        that.cleanSearchList();
        that.filterSectionOnBlockmap([]);
        $('#search-list').addClass('hidden');
      }
    }, 150);
  }


  /*
  *
  * INPUT BEHAVIOR
  *
  */

  onInputKeyPress(evt) {
    const that = this;
    setTimeout(function() {
      if (that.isKeyPressCharacter(evt)) {
        that.cleanSearchList();
        const sections = that.status.blockmap.moduleRef['getElementsByStatus']('available');
        const selection = [];
        const searchVal = $('#search-input').val().toLowerCase();
        if (that.app_config.mapType === 'areamap') {
          if (searchVal !== '') {
            const result = [];
            for (const blockID in that.blockhash) {
              if (blockID.toLowerCase().split('_')[1].indexOf(searchVal) !== -1) {
                result.push(blockID);
              }
            }
            for (let i = 0; i < result.length; ++i) {
              const sectionID = result[i];
              that.generateSearchList(sectionID);
              const areaID = that.blockhash[sectionID];
              selection.push(areaID);
            }
          }
        } else {
          sections.forEach(function(sec) {
            const searchVal = $('#search-input').val().toLowerCase();
            const sectionVal = sec.id.toLowerCase();
            if (searchVal !== '' && sectionVal.indexOf(searchVal) > -1) {
              that.generateSearchList(sec.id);
              selection.push(sec.id);
            }
          });
        }
        if ($('#search-input').val().length > 0) {
          if (selection.length === 0) {
          that.generateSearchList(null);
          that.filterSectionOnBlockmap([]);
          } else {
            that.filterSectionOnBlockmap(selection);
            that.afterListCreation();
          }
        } else {
          that.filterSectionOnBlockmap([]);
          that.cleanSearchList();
        }
      }
    }, 50);
  }

  isKeyPressCharacter(evt) {
    if (typeof evt.which === 'undefined') {
        // This is IE, which only fires keypress events for printable keys
        return true;
    } else if (typeof evt.which === 'number' && evt.which > 0) {
        // In other browsers except old versions of WebKit, evt.which is
        // only greater than zero if the keypress is a printable key.
        // We need to filter out backspace and ctrl/alt/meta key combinations
      const x = this.getSearchList();
      if (evt.keyCode === 40 && $('#search-input').val().length > 0) {
        // Arrow down
        if (this.currentFocus <= x.length - 1) {
          this.currentFocus++;
        }
        this.addActive(x);
      } else if (evt.keyCode === 38 && $('#search-input').val().length > 0) {
        // Arrow up
        if (this.currentFocus > -1) {
          this.currentFocus--;
        }
        this.addActive(x);
      } else if (evt.keyCode === 13 && $('#search-input').val().length > 0) {
        // Enter
        if (this.currentFocus > -1) {
          /*and simulate a click on the "active" item:*/
          if (x) { x[this.currentFocus].click(); }
        }
      } else {
        return !evt.metaKey && !evt.altKey;
      }
    }
    return false;
  }

  cleanSearchList() {
    $('#search-list').empty();
    this.currentFocus = 0;
  }

  addActive(x) {
      /*a function to classify an item as "active":*/
      if (!x) { return false; }
      /*start by removing the "active" class on all items:*/
      this.removeActive(x);
      if (this.currentFocus >= x.length) { this.currentFocus = 0; }
      if (this.currentFocus < 0) { this.currentFocus = (x.length - 1); }
      /*add class "autocomplete-active":*/
      x[this.currentFocus].classList.add('active');

      if (this.app_config.mapType === 'areamap') {
      const a = $(x[this.currentFocus]).attr('data-id');
      this.filterSectionOnBlockmap(this.blockhash[a]);
    } else {
      this.filterSectionOnBlockmap($('.active').attr('data-id'));
    }
      $('.active').get(0).scrollIntoView();
  }

  afterListCreation() {
    const that = this;
    $('.search-item').click(function() {
      if (that.app_config.mapType === 'areamap') {
        const a = $(this).attr('data-id');
        that.locateSeatmap(that.blockhash[a]);
      } else {
        that.locateSeatmap($(this).attr('data-id'));
      }
    });

    $('.search-item').mouseenter(function() {
      this.currentFocus = -1;
      $('.active').removeClass('active');
      $(this).addClass('active');

      if (that.app_config.mapType === 'areamap') {
        const a = $(this).attr('data-id');
        that.filterSectionOnBlockmap(that.blockhash[a]);
      } else {
        that.filterSectionOnBlockmap($('.active').attr('data-id'));
      }
    });
    const x = this.getSearchList();
    if (x.length > 0) { x[this.currentFocus].classList.add('active'); }
  }

  generateSearchList(id) {
    if (!id) { return; }
    let content = '<div class=\'search-item\' data-id=\'\'><span class=\'search-item-lab\'>Section not found.</span></div>';
    if (typeof(id) !== 'undefined') {
      content = '<div class=\'search-item\' data-id=' + id +
      '><span class=\'search-item-lab\'>Section </span><span class=\'search-item-val\'>' +
      id.split('_')[1] + '</span></div>';
    }
    $('#search-list').append(content);
  }

  getSearchList() {
    if (document.getElementById('search-list')) {
      return document.getElementById('search-list').getElementsByTagName('div');
    }
    return [];
  }

  locateSeatmap(id) {
    // if (this.status.view3d.visible === true) {
    //   this.tk3d.onSeatMapMiniClick();
    // }
    $('#minimap').removeClass('hidden');
    $('#minimap').css('z-index', '1');
    this.status.seatmap.size = 'large';
    if (this.status.seatmap.moduleRef['getMapId']() !== id) {
      this.status.seatmap.showLoader = true;
    }
    this.status.seatmap.moduleRef['setMaxZoom'](6);
    this.status.seatmap.moduleRef['setMinZoom'](1);
    this.status.seatmap.moduleRef['enableZoom']();

    this.status.view3d.visible = false;
    this.status.view3d['size'] = 'mini';
    this.status.blockmap['size'] = 'mini';
    this.status.seatmap.visible = true;

    this.status.blockmap.selected = null;
    this.status.blockmap.selected = id;

    this.status.resizeForce = true;

    this.cleanSearchList();
    this.filterSectionOnBlockmap([]);
  }

  removeActive(x) {
      /*a function to remove the "active" class from all autocomplete items:*/
      for (let i = 0; i < x.length; i++) {
        x[i].classList.remove('active');
      }
  }

  resetSearch() {
    this.currentFocus = 0;
    $('#search-').blur();
    $('#search-list').blur();
    $('#search-input').val('');
    this.cleanSearchList();
    $('#search-list').addClass('hidden');
  }

  filterSectionOnBlockmap(section: string[]) {
    if (typeof section === 'string') {
      section = [section];
    }
    this.status.blockmap.moduleRef['removeStatus'](this.status.blockmap.moduleRef['getElementsByStatus']('filtered'), 'filtered');
    if (section.length === 0) {
      const toAvailable = this.status.blockmap.moduleRef['getElementsByStatus']('toAvailable');
      if (!toAvailable || toAvailable.length === 0) {
        return;
      }
      this.status.blockmap.moduleRef['setAvailability'](this.status.blockmap.moduleRef['getElementsByStatus']('toAvailable'));
      this.status.blockmap.moduleRef['removeStatus'](this.status.blockmap.moduleRef['getElementsByStatus']('toAvailable'), 'toAvailable');
      return;
    }
    this.status.blockmap.moduleRef['addStatus'](section, 'filtered');
    this.status.blockmap.moduleRef['addStatus'](this.status.blockmap.moduleRef['getElementsByStatus']('available'), 'toAvailable');
    this.status.blockmap.moduleRef['setAvailability']([]);
    section.forEach(elementId => {
      const element = this.status.blockmap.moduleRef['getElementById'](elementId);
      if (element) {
        if (element.hasStatus('toAvailable')) {
          this.status.blockmap.moduleRef['setElementAvailable'](element);
        }
      }
    });
  }

  ngOnInit() {
    const that = this;
    $(function() {
      $('#search-input').focusin(function() {
        that.showSearchInput();
      });
      $('#search-input').focusout(function() {
        that.hideSearchInput();
      });
      $('#search-button').focusout(function() {
          that.hideSearchInput();
      });

      $('#search-list').mouseleave(function() {
        if (!$('#search-input').is(':focus')) {
          $('#search-list').addClass('hidden');
        }
      });
    });

    // this.translator.sectionsInAreas.subscribe(
    //   sectionsInAreas => {
    //     this.blockhash = sectionsInAreas['sectors'];
    //   }
    // );
  }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ReplaySubject, throwError, Subject } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import { DjangoSessionAuthenticationService } from 'src/app/services/django-session-authentication.service';

@Injectable({
    providedIn: 'root'
})

export class APIConnectionService {

    // Application Basket
    public Basket: ReplaySubject<object[]> = new ReplaySubject(1);

    constructor(private http: HttpClient,
                private auth: DjangoSessionAuthenticationService) {
    }
    // General - Send Request
    private sendRequest(endpoint, body = null) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': `application/json`
            })
        };
        const finalUrl = `${endpoint}`;
        const observable = body ? this.http.post(finalUrl, body, httpOptions) : this.http.get(finalUrl, httpOptions);
        return observable.pipe();
    }

    /*
    *   PRIVATE METHODS
    */

    // Get Plan Availability
    private _getPlanAvailability(planId, upgrade = false) {
        let endpoint;
        if (upgrade) {
            endpoint =  `/customer-plan/${planId}/upgrade/${upgrade}/availability/`;
        } else {
            endpoint =  `/customer-plan/${planId}/availability/`;
        }
        return this.sendRequest(endpoint);
    }

    // Get Plan Section Availability
    private _getPlanSectionAvailability(planId, sectionId, upgrade = false) {
        let endpoint;
        if (upgrade) {
            endpoint =  `/customer-plan/${planId}/upgrade/${upgrade}/availability/${sectionId}/`;
        } else {
            endpoint =  `/customer-plan/${planId}/availability/${sectionId}/`;
        }
        return this.sendRequest(endpoint);
    }

    // Transaction Hold Create
    private _createHoldTransaction(planId, seats: string[], upgrade = false) {
        const endpoint =  `/transaction/`;
        let body;
        if (upgrade) {
            body = {
                action: 'hold',
                customer_plan_id: planId,
                seats,
                plan_to_upgrade: upgrade
            };
        } else {
            body = {
                action: 'hold',
                customer_plan_id: planId,
                seats
            };
        }
        return this.sendRequest(endpoint, body);
    }

    // Change seats status from transaction
    // /tmp/seat_transaction/[transaction_id]/
    // body: {'seats':['S_204-1-1, S_Lexus Club]}
    private _editTransactionSeats(transactionId: number, seats: string[]) {
        const endpoint =  `/seat_transaction/${transactionId}/`;
        const body = {
            seats
        };
        return this.sendRequest(endpoint, body);
    }

    /**
     * Checks isolated seats
     * @param planId of customer plan
     * @param seats to be checked
     */
    private _checkIsolatedSeats(planId: number, seats: string[]) {
        const endpoint = `/customer-plan/${planId}/check_isolation/`;
        const body = {
            seats
        };
        return this.sendRequest(endpoint, body);
    }

    /*
    *   PUBLIC METHODS
    */

    public getPlanAvailability(planId, upgrade = false): Observable<any> {
        return this._getPlanAvailability(planId, upgrade).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    public getPlanSeats(planId): Observable<object[]> {
        return this.auth.getUserLogged$().pipe(
            map((userData) => {
                for (const plan of userData['plans']) {
                    if (plan['id'] === planId) {
                        return plan['customer_plan_seats'];
                    }
                }
                return [];
            })
        );
    }

    public getPlanSectionAvailability(planId, sectionId, upgrade = false): Observable<any> {
        return this._getPlanSectionAvailability(planId, sectionId, upgrade).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    public createHoldTransaction(planId, seats: string[], upgrade = false): Observable<any> {
        return this._createHoldTransaction(planId, seats, upgrade).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    public editTransactionSeats(transactionId: number, seats: string[]): Observable<any> {
        return this._editTransactionSeats(transactionId, seats).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    public checkIsolatedSeats(planId, seats: string[]): Observable<any> {
        return this._checkIsolatedSeats(planId, seats).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

}

import { Component, OnInit, Input, Inject, ChangeDetectorRef, HostListener } from '@angular/core';
import { FlowControlService } from '../services/flow-control.service';
import { UserService } from 'src/app/services/user.service';
import { ThemeSelectorService } from 'src/app/services/theme-selector.service';
import { Subject } from 'rxjs';
import { CustomerPlan, Plan, Seat } from 'src/app/shared/models';
import { DigitalvenueConfiguration } from '../digitalvenue-configuration.model';
import { APP_CONFIG, DVM_CONFIG } from '../digitalvenue.configuration';
import * as globals from '../globals';
import { MessagingService } from 'src/app/shared/modals/messaging.service';
import { Router } from '@angular/router';
import { DVMService } from '../services/dvm.service';
import { DVMConfiguration } from '../dvmconfiguration.model';
import { PricingService } from '../services/pricing.service';
import { add } from 'ngx-bootstrap/chronos/public_api';


@Component({
  selector: 'app-sub-interface',
  templateUrl: './sub-interface.component.html',
  styleUrls: ['./sub-interface.component.scss']
})
export class SubInterfaceComponent implements OnInit {

    @Input() isSelectionAllowed: boolean;
    @Input() discardedSeats: object;
    @Input() selectedSeats: Seat[];
    keptSeats: object[] = [];
    Object = Object;
    globals = globals;
    sectionlab = 'SECTION';
    rowlab = 'ROW';
    seatlab = 'SEAT';
    pricelab = 'PRICE';
    subInterfaceTitle;
    plans = this.userService.getCustomerPlans();
    customerName = this.userService.getUser().first_name;

    view3dTicket;

    selectedMap = 0;
    selectableMaps: object;
    selectableMapsArray: Array<object>;

    accordionHashSubject = new Subject();
    selectAllSubject = new Subject();

    customerPlan: CustomerPlan;
    upgradePlan: Plan;
    innerWidth = window.innerWidth;

    infoTextPreview = 'Browse the current inventory for this plan.';
    infoTextRelocation = 'Click on the \'Relocate\' button to relocate seats.';
    infoTextSeatselection = 'Select your new seats in the map.';

    accordionHash = {};

    /**
     * Event that detects the window size
     * @param event window resize
     */
    @HostListener('window:resize', ['$event'])
    sizeChange(event) {
        // gets the window width on reize
        this.innerWidth = window.innerWidth;
    }

    get backButtonText() {
        if (this.isSelectionAllowed) {
            return 'Back';
        } else {
            return 'Home';
        }
    }

    get currentSeats() {
        let selectedSeatsByPT = [];
        selectedSeatsByPT[0] = [];
        selectedSeatsByPT[1] = [];
        if (this.isSelectionAllowed) {
            if (this.selectedSeats != null) {
                const sortedSelectedSeats = this.sortSeats(this.selectedSeats);
                for (const selectedSeat of sortedSelectedSeats) {
                    if (selectedSeatsByPT[0].length < Object.keys(this.discardedSeats).length) {
                        selectedSeatsByPT[0].push(selectedSeat);
                    } else {
                        selectedSeatsByPT[1].push(selectedSeat);
                    }
                }
            }
        } else {
            selectedSeatsByPT = this.selectedSeats;
        }
        return selectedSeatsByPT;
    }

    sortSeats(seatsArray: Seat[]) {
        const relocatedSeats = [];
        const addOns = [];
        for (const selectedSeat of seatsArray) {
            if (selectedSeat.price_type === this.pricing.maxPrice.price_type) {
                relocatedSeats.push(selectedSeat);
            } else {
                addOns.push(selectedSeat);
            }
        }
        return relocatedSeats.sort((a, b) => a.price - b.price).concat(addOns);
    }

    get discardedSeatsCount() {
        if (this.discardedSeats != null) {
            return Object.keys(this.discardedSeats).length;
        } else {
            return 0;
        }
    }

    get selectedSeatsCount() {
        if (this.selectedSeats != null) {
            return this.selectedSeats.length;
        } else {
            return 0;
        }
    }

    get isHighlighted() {
        return !this.isSelectionAllowed && this.discardedSeatsCount === 0 && this.isRelocationAvailable();
    }

    constructor(public flowControl: FlowControlService,
                private pricing: PricingService,
                public userService: UserService,
                private dvmService: DVMService,
                private themeSelector: ThemeSelectorService,
                private cdr: ChangeDetectorRef,
                private messageService: MessagingService,
                private router: Router,
                @Inject(APP_CONFIG) public dvConfig: DigitalvenueConfiguration,
                @Inject(DVM_CONFIG) public dvmConfig: DVMConfiguration) {}

    toggleAccordion(accordionId) {
        if (!this.accordionHash[accordionId]) {
            this.accordionHash[accordionId] = true;
        } else {
            this.accordionHash[accordionId] = !this.accordionHash[accordionId];
        }
    }

    selectAll(value) {
        this.selectAllSubject.next(value);
    }

    selectChange(newValue) {
        this.selectedMap = newValue;
        // this.flowControl.setCurrentMap(this.selectedMap);
        this.flowControl.currentVenue = newValue;
        this.dvmService.changeMapConfiguration(this.selectableMapsArray[newValue]['venue']);
    }

    backHome() {
        const title = 'Return to the previous step?';
        const message = 'You will lose your current selection. Are you sure you want to go back to the previous step?';
        const acceptBtnName = 'Confirm';
        const closeBtnName = 'Close';
        this.messageService.info(title, message, acceptBtnName, () => {
            if (this.isSelectionAllowed && !this.flowControl.upgradePlan) {
                this.router.navigate(['/digitalvenue/preview', this.flowControl.currentPlan]);
            } else {
                this.router.navigate(['/home']);
            }
        }, closeBtnName);
    }

    isRelocationAvailable() {
        const appointmentTimestamp = new Date(this.customerPlan.appointment_date_from).getTime();
        const now = new Date().getTime();
        return (now >= appointmentTimestamp) ? true : false;
    }

    ngOnInit() {
        this.initializeDVM(this.dvmConfig);
        this.customerPlan = this.userService.getCustomerPlanById(this.flowControl.currentPlan);
        this.selectableMaps = this.flowControl.getAllMaps();
        this.selectableMapsArray = Object.values(this.selectableMaps);
        // const upgradablePlans = this.customerPlan.plan_change_availability.slice();
        // this.rearrangeArray(upgradablePlans);
        if (this.flowControl.upgradePlan) {
            this.customerPlan.plan_change_availability.forEach(element => {
                if (element.id === this.flowControl.upgradePlan) {
                    this.upgradePlan = element;
                }
            });
        } else {
            this.upgradePlan = null;
        }
        this.keptSeats = this.calculateKeptSeats();
    }

    calculateKeptSeats() {
        const customerPlanSeats = this.customerPlan.customer_plan_seats;
        const discardedSeatsKeys = Object.keys(this.discardedSeats);
        console.log(this.discardedSeats, discardedSeatsKeys);
        const keptSeats = [];
        customerPlanSeats.forEach(customerPlanSeat => {
            // Removing all seats that have Price = 0
            // This takes Parking into consideration as well
            if (customerPlanSeat.price !== 0 && !(customerPlanSeat.section === 'Lot E' ||
                customerPlanSeat.section === 'Lot W' ||
                customerPlanSeat.section === 'Lot 1' ||
                customerPlanSeat.section === 'Lot C' ||
                customerPlanSeat.section === 'Lexus Club')) {
                let isDiscarded = false;
                discardedSeatsKeys.forEach(discardedSeat => {
                    if (discardedSeat === customerPlanSeat.id) {
                        isDiscarded = true;
                    }
                });
                if (!isDiscarded) {
                    keptSeats.push(customerPlanSeat);
                }
            }
        });
        return keptSeats;
    }

    // tslint:disable-next-line: use-lifecycle-interface
    ngAfterViewChecked() {
        if (!globals.isMobile()) {
            const ticketsListHeight = document.getElementById('main-interface').offsetHeight -
            (document.getElementById('data-interface-container') as HTMLElement).offsetHeight;
            document.getElementById('ticketsList').style.maxHeight = ticketsListHeight + 'px';
        } else {
            document.getElementById('ticketsList').style.maxHeight = '154px';
            // document.getElementById('ticketsList').style.maxHeight = window.innerHeight - 198 / 2 + 'px';
        }
        this.cdr.detectChanges();
    }

    getTheme() {
        return this.themeSelector.isDarkTheme;
    }

    private initializeDVM(config) {
        const viewer3dConfig = JSON.parse(JSON.stringify(config));
        viewer3dConfig.container = 'ticket-accordion-3dview';
        viewer3dConfig.plugins = ['navigation'];
        DVM.loadModule('3d_viewer', viewer3dConfig)
          .then((viewer3d) => {
            this.view3dTicket = viewer3d;
          })
          .catch((err) => {
            console.error(err);
          });
      }

}

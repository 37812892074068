import { Component, Input, OnInit, Inject } from '@angular/core';
import { FlowControlService } from '../services/flow-control.service';
import { APP_CONFIG } from 'src/app/configuration/ticket-management-portal-configuration';
import { DigitalvenueConfiguration } from '../digitalvenue-configuration.model';
import { DVMService } from '../services/dvm.service';
import { DVM_CONFIG } from '../digitalvenue.configuration';
import { DVMConfiguration } from '../dvmconfiguration.model';
import { IsolatedSeatsService } from '../services/isolated-seats.service';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  @Input() isSelectionAllowed;

  get isStep1() {
    if (this.dvmService.viewer) {
      return this.dvmService.viewer.getMapId() === this.dvmConfig.map_id;
    }
    return false;
  }

  get isStep2() {
    if (this.dvmService.viewer && this.dvmService.viewer.getMapId()) {
      return this.dvmService.viewer.getMapId() !== this.dvmConfig.map_id && !this.dvmService.viewer3d.getViewId();
    }
    return false;
  }

  get isStep3() {
    if (this.dvmService.viewer3d && this.dvmService.viewer3d.getViewId()) {
      return true;
    }
    return false;
  }

  back() {
    const loadOptions = JSON.parse(JSON.stringify(this.dvmConfig));
    loadOptions.venue_id = this.dvmService.viewer.getVenueId();
    if (this.isStep2) {
      this.isolatedSeats.resetBuffer();
    } else if (this.isStep3) {
      loadOptions.map_id = this.dvmService.viewer.getMapId();
      this.dvmService.viewer3d.reset();
    }
    this.dvmService.loadMap(loadOptions);
  }

  isSectionView() {
    if (this.flowControl.suitesHash[this.dvmService.viewer3d.getViewId().split('-')[0].split('(')[0]] ||
    this.dvmService.viewer3d.getViewId().split('-').length === 1) {
      return true;
    }
    return this.flowControl.isGASelected;
  }

  get currentSection() {
    if (this.isStep3) {
      return this.dvmService.viewer3d.getViewId().split('-')[0].split('_')[1].split('(')[0];
    }
    if (this.dvmService.viewer) {
      return this.dvmService.viewer.getMapId().split('-')[0].split('_')[1];
    }
  }

  get currentRow() {
    if (this.isStep3 && this.dvmService.viewer3d.getViewId()) {
      return this.dvmService.viewer3d.getViewId().split('-')[1];
    }
  }

  get currentSeat() {
    if (this.isStep3 && this.dvmService.viewer3d.getViewId()) {
      return this.dvmService.viewer3d.getViewId().split('-')[2];
    }
  }

  constructor(private flowControl: FlowControlService,
              private dvmService: DVMService,
              private isolatedSeats: IsolatedSeatsService,
              @Inject(DVM_CONFIG) public dvmConfig: DVMConfiguration,
              @Inject(APP_CONFIG) public dvConfig: DigitalvenueConfiguration) { }

  ngOnInit() {
  }

}

import { Inject, Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DjangoSessionAuthenticationService } from '../services/django-session-authentication.service';
import { UserService } from '../services';
import { APP_CONFIG } from '../configuration/ticket-management-portal-configuration';
import { TicketManagementPortalConfiguration } from '../configuration/ticket-management-portal-configuration.model';

@Injectable({
  providedIn: 'root'
})
export class LoginAuthGuard implements CanActivate, CanActivateChild {

  constructor(@Inject(APP_CONFIG) private appConfig: TicketManagementPortalConfiguration,
              private authService: DjangoSessionAuthenticationService,
              private router: Router,
              private userService: UserService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.appConfig.isProcessActive) {
        return this.authService.getUserLogged().then((user) => {
            if (user) {
                this.router.navigate(['/home']);
                return false;
            } else {
                return true;
            }
            // @ts-ignore
          }, Error((e) => {
            return false;
          }));
      }
      return true;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      return this.authService.canActivate();

  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AddApiUrlInterceptor, WithCredentialsInterceptor, HttpXsrfInterceptorMod, AddSeasonInterceptor } from './interceptors';
import { ModalComponent } from './modals/modal.component';
import { MessagingService } from './modals/messaging.service';
import { SalesRepComponent } from './sales-rep/sales-rep.component';
import { CongratulationsModalComponent } from './modals/congratulations-modal/congratulations-modal.component';
import { ModalsService } from './modals/modals.service';
import { ModalModule } from 'ngx-bootstrap';
import { ErrorModalComponent } from './modals/error-modal/error-modal.component';
import { RestartModalComponent } from './modals/restart-modal/restart-modal.component';
import { ParkingSlotModalComponent } from './modals/parking-slot-modal/parking-slot-modal.component';
import { LexusModalComponent } from './modals/lexus-modal/lexus-modal.component';
import { FormsModule } from '@angular/forms';
import { PanoViewModalComponent } from './modals/pano-view-modal/pano-view-modal.component';
import { PlanSeatElementModalComponent } from './modals/plan-seat-element-modal/plan-seat-element-modal.component';
import { ExtraModalComponent } from './modals/extra-modal/extra-modal.component';
import { InfoModalComponent } from './modals/info-modal/info-modal.component';



@NgModule({
  declarations: [
    ModalComponent,
    SalesRepComponent,
    CongratulationsModalComponent,
    ErrorModalComponent,
    RestartModalComponent,
    ParkingSlotModalComponent,
    LexusModalComponent,
    PanoViewModalComponent,
    PlanSeatElementModalComponent,
    ExtraModalComponent,
    InfoModalComponent
  ],
  imports: [
    ModalModule.forRoot(),
    CommonModule,
    FormsModule
  ],
  exports: [
    SalesRepComponent
  ],
  entryComponents: [
    ModalComponent,
    CongratulationsModalComponent,
    ErrorModalComponent,
    RestartModalComponent,
    ParkingSlotModalComponent,
    LexusModalComponent,
    PanoViewModalComponent,
    PlanSeatElementModalComponent,
    ExtraModalComponent,
    InfoModalComponent
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AddApiUrlInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: WithCredentialsInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptorMod, multi: true},
    // {provide: HTTP_INTERCEPTORS, useClass: AddSeasonInterceptor, multi: true},
    MessagingService,
    ModalsService
  ]
})
export class SharedModule { }

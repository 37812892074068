import { Injectable } from '@angular/core';
import { Seat } from 'src/app/shared/models';
import { FlowControlService } from './flow-control.service';

@Injectable({
  providedIn: 'root'
})
export class ExtraService {

  public extraHash: {
    parkingHashC: { [key: string]: Seat },
    parkingHashW: { [key: string]: Seat },
    lexusHash: { [key: string]: Seat },
  } = {
    parkingHashC: {},
    parkingHashW: {},
    lexusHash: {}
  };

  extraPrices = {
    parking_c: 5100,
    parking_w: 5100,
    lexus: 5000
  };

  parkingCountC;
  parkingCountW;
  lexusCount;

  setExtraCount() {
    this.parkingCountC = Object.keys(this.extraHash.parkingHashC).length;
    this.parkingCountW = Object.keys(this.extraHash.parkingHashW).length;
    this.lexusCount = Object.keys(this.extraHash.lexusHash).length;
  }

  init() {
    this.extraHash = {
        parkingHashC: {},
        parkingHashW: {},
        lexusHash: {}
      };
  }

  constructor(private flowControl: FlowControlService) {}

  public addParkingToBasket(parkingSection: string, parkingSlots: number): void {
    if (parkingSection && parkingSlots) {
      for (let i = 0; i < parkingSlots; i++) {
        let price;
        if (parkingSection === 'Lot C') {
          price = this.extraPrices.parking_c;
        } else if (parkingSection === 'Lot W') {
          price = this.extraPrices.parking_w;
        }
        const parkingObj: Seat = {
          id: parkingSection + '-GA-' + i,
          section: parkingSection,
          price
        };
        this.flowControl.Basket[parkingObj.id] = parkingObj;
        this.flowControl.extraBasket.push(parkingObj.section);
      }
    }
  }

  public addLexusToBasket(lexusSlot: number, lexusPasses: number, success = null): void {
    for (let i = 0; i < lexusSlot; i++) {
      const lexusObj: Seat = {
        id: 'S_Lexus Club-GA' + lexusPasses + '-' + i,
        section: 'S_Lexus Club',
        price: this.extraPrices.lexus // Multiplicar por lexusPasses?
      };
      this.flowControl.Basket[lexusObj.id] = lexusObj;
      this.flowControl.extraBasket.push(lexusObj.section);

    }
    // success('added');
  }
}

import { Injectable } from '@angular/core';
import { DVMService } from '../services/dvm.service';

@Injectable()
export class General3dHandler {
    constructor(private event, protected dvmService: DVMService) {
        this.dvmService.subscribe3dHandler(event, this.handle.bind(this));
    }

    protected handle(...args) {}

}

import { Injectable, Inject } from '@angular/core';
import { DVMService } from '../services/dvm.service';
import { GeneralHandler } from './general.handler';
import { DVM_CONFIG, APP_CONFIG } from '../digitalvenue.configuration';
import { DVMConfiguration } from '../dvmconfiguration.model';
import { IsolatedSeatsService } from '../services/isolated-seats.service';
import { FlowControlService } from '../services/flow-control.service';
import { Seat } from 'src/app/shared/models';
import { ErrorJsonService } from 'src/app/services';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { PricingService } from '../services/pricing.service';
import { DigitalvenueConfiguration } from '../digitalvenue-configuration.model';
import { APIConnectionService } from '../services/api-connection.service';

@Injectable()
export class ClickSeatselectionHandler extends GeneralHandler {
    constructor(protected dvmService: DVMService,
                private pricing: PricingService,
                private errorCode: ErrorJsonService,
                private modals: ModalsService,
                private isolatedSeatsService: IsolatedSeatsService,
                private connection: APIConnectionService,
                private flowControl: FlowControlService,
                @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
                @Inject(APP_CONFIG) private appConfig: DigitalvenueConfiguration) {
        super('click', dvmService);
    }

    sectionClickHandler(node, viewer) {
      if (node.state === 'available' && !node.id.includes('S_TB')) {
          const loadOptions = JSON.parse(JSON.stringify(this.dvmConfig));
          loadOptions.map_id = node.id;
          loadOptions.venue_id = this.dvmService.viewer.getVenueId();
          this.dvmService.loadMap(loadOptions);
      } else if (node.id.includes('S_TB')) {
        this.connection.getPlanSectionAvailability(this.flowControl.currentPlan,
          node.id, this.flowControl.upgradePlan).subscribe(
          planSectionAvailability => {
            console.log(planSectionAvailability);
          });
          const id = node.id;
          const seatObject: Seat = {
              id: `${id}-all-all`,
              section: id.split('_')[1],
              seat_row: 'all',
              seat: null,
              price: null,
              price_type: null
          };
          if (node.state === 'selected') {
              viewer.unselect(id);
              // borrarlo del basket
              const basketKeys = Object.keys(this.flowControl.Basket);
              let value;
              for (const item of basketKeys) {
                  if (item.includes(id)) {
                      value = item;
                      break;
                  }
              }
              delete this.flowControl.Basket[value];
          } else if (node.state === 'available') {
              viewer.select(id);
              // añadirlo al basket
              // vamos a pedir la seccion para conseguir la silla
              this.connection.getPlanSectionAvailability(this.flowControl.currentPlan, node.id)
                  .subscribe((response) => {
                      const correctId = Object.keys(response)[0];
                      seatObject.id = correctId;
                      seatObject.seat = correctId.split('-')[2];
                      seatObject.price_type = response[seatObject.id].price_type.name;
                      seatObject.price = response[seatObject.id].price_type.prices[0].price;
                      this.flowControl.Basket[seatObject.id] = seatObject;
                  });
          }
      }
  }

    seatClickHandler(node, viewer) {
        if (node.state === 'available' || node.state === 'selected') {
            const id = node.id;
            const split = id.split('_')[1].split('-');
            const section = split[0];
            // tslint:disable-next-line: variable-name
            const seat_row = split[1];
            const seat = split[2];
            const price_type = this.pricing.seatpricing[id].price_type;
            const seatObject: Seat = {
              id, section, seat_row, seat, price: null, price_type
            };

            // IF NODE IS NOT SELECTED
            if (node.state !== 'selected') {
              // Setting the price
              const price = this.pricing.setPricing(id);
              seatObject.price = price;
              // ISOLATED SEATS CHECK
              const action = 'add';
              if (this.isolatedSeatsService.singleSeatsBuffer.length > 0 && this.appConfig.preventIsolatedSeats) {
                this.isolatedSeatsService.singleSeatHandling(seatObject, id, action);
              } else if (this.flowControl.Basket[id] == null) {
                // If Relocated Seats are not completed
                console.log(Object.keys(this.flowControl.Basket).length, this.flowControl.relocationSeatsCount, this.flowControl.maxAddons,
                (this.flowControl.relocationSeatsCount + this.flowControl.maxAddons));
                if (Object.keys(this.flowControl.Basket).length < (this.flowControl.relocationSeatsCount + this.flowControl.maxAddons)) {
                  // ISOLATED SEATS ALROGITHM
                  let isolatedSeats = [];
                  if (this.appConfig.preventIsolatedSeats) {
                    isolatedSeats = this.isolatedSeatsService.checkIsolatedSeats([node], seatObject, action);
                  }
                  if (isolatedSeats.length === 0) {
                    viewer.select(node);
                    this.flowControl.Basket[id] = seatObject;
                  } else {
                    this.errorCode.getErrorByCode(1006).subscribe(
                      errorMessage => {
                        this.modals.errorModal(errorMessage);
                      }
                    );
                  }
                } else {
                  this.errorCode.getErrorByCode(1007).subscribe(
                    errorMessage => {
                      this.modals.errorModal(errorMessage);
                    }
                  );
                }
              }
            // IF NODE IS SELECTED
            } else {
              // ISOLATED SEATS CHECK
              const action = 'remove';
              if (this.isolatedSeatsService.singleSeatsBuffer.length > 0 && this.appConfig.preventIsolatedSeats) {
                this.isolatedSeatsService.singleSeatHandling(seatObject, id, action);
              } else if (this.flowControl.Basket[id] != null) {
                // ISOLATED SEATS ALROGITHM
                viewer.unselect(node);
                const selectedElements = viewer.getNodesByState('seat', 'selected');
                let isolatedSeats = [];
                if (this.appConfig.preventIsolatedSeats) {
                  isolatedSeats = this.isolatedSeatsService.checkIsolatedSeats(selectedElements, seatObject, action);
                }
                if (isolatedSeats.length === 0) {
                  delete this.flowControl.Basket[id];
                  this.pricing.restorePrice(id);
                } else {
                  this.errorCode.getErrorByCode(1006).subscribe(
                    errorMessage => {
                      this.modals.errorModal(errorMessage);
                    }
                  );
                }
              }
            }
        }
    }

    protected handle(obj) {
        // Close popovers
        const tooltip: HTMLElement = document.querySelector('#tooltip');
        tooltip.removeAttribute('data-show');

        // Handle
        const viewer = obj.instance;
        const nodes = obj.nodes;
        if (nodes.length) {
            if (nodes[0].type === 'section') {
              if (this.flowControl.suitesHash[nodes[0].id]) {
                  this.dvmService.load3DView(nodes[0].id);
              } else {
                  this.sectionClickHandler(nodes[0], viewer);
              }
            } else if (nodes[0].type === 'seat') {
                this.seatClickHandler(nodes[0], viewer);
            }
        }
    }
}

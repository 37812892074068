import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { ThemeSelectorService } from 'src/app/services';
import { ExtraService } from 'src/app/digitalvenue/services/extra.service';
import { Seat } from '../../models';
import { FlowControlService } from 'src/app/digitalvenue/services/flow-control.service';
import { Router } from '@angular/router';
import { ParkingService } from 'src/app/digitalvenue/services/parking.service';

@Component({
  selector: 'app-lexus-modal',
  templateUrl: './lexus-modal.component.html',
  styleUrls: ['./lexus-modal.component.scss']
})
export class LexusModalComponent implements OnInit {

  title: string;
  message: string;
  // lexusArray: Array<number>;
  // lexusAdmissionSlots = 1;
  // isDarkTheme: boolean;
  // @Input() success;
  parkingArray: Array<number>;
  parkingAdmissionSlots = 1;
  isDarkTheme: boolean;
  @Input() success;
  public parkingSlots = this.parkingService.parkingSlots;

  constructor(public bsModalRef: BsModalRef,
              private themeSelector: ThemeSelectorService,
              private extraService: ExtraService,
              private flowControl: FlowControlService,
              private router: Router,
              public parkingService: ParkingService) {
    this.title = 'Lexus Club';
    this.message = `How many Lexus passes would you like to add?`;
    // this.lexusArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  }

  ngOnInit() {
  }



 /**
  * Hides the modal and confirms the lexus selection
  */
  confirm(): void {
    this.bsModalRef.hide();
    for (let i = 1; i <= this.parkingAdmissionSlots; i++) {
      const lexusSeat: Seat = {
        id: 'S_Lexus Club-GA-' + i,
        section: 'GA',
        price: 5000
      };
      this.extraService.extraHash.lexusHash[lexusSeat.id] = lexusSeat;
      // this.flowControl.Basket[lexusSeat.id] = lexusSeat;
      }
    this.success('yes');
  }

  /**
   * Hides the modal
   */
  decline(): void {
    this.bsModalRef.hide();
    this.success('no');
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

}

export function isMobile() {
    if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        return true;
    } else {
        return false;
    }
}

// export function getMinZoom(mapcontainer_id) {
//     const container = $('#' + mapcontainer_id);
//     const bg = container.find('.svg-img-background');
//     let svg_ar;

//     if (bg.length > 0) {
//         svg_ar = bg[0].width.baseVal.value / bg[0].height.baseVal.value;
//     } else {
//         return 1;
//     }

//     const con_ar = container.width() / container.height();

//     return svg_ar / con_ar;
// }

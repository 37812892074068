import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LocalStorageService } from './local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class ThemeSelectorService {
  public isDarkTheme: boolean;
  private _darkTheme = new Subject<boolean>();
  private isDarkTheme$ = this._darkTheme.asObservable();

  setDarkTheme(isDarkTheme: boolean): void {
    this._darkTheme.next(isDarkTheme);
  }

  getIsDarkTheme(): boolean {
    return this.isDarkTheme;
  }

  constructor(private storageService: LocalStorageService) {
    if (this.storageService.isStorageAvailable) {
      if (this.storageService.getItem('isDarkTheme') === null) {
        this.storageService.setItem('isDarkTheme', JSON.stringify(false));
      }
    }
    this.isDarkTheme = JSON.parse(this.storageService.getItem('isDarkTheme'));
    this.isDarkTheme$.subscribe(
      isDarkTheme => {
        this.isDarkTheme = isDarkTheme;
        this.storageService.setItem('isDarkTheme', JSON.stringify(isDarkTheme));
      }
    );
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { BsModalRef, ModalOptions, BsModalService } from 'ngx-bootstrap';
import { ThemeSelectorService, UserService, DjangoSessionAuthenticationService, ErrorJsonService } from 'src/app/services';
import { ExtraService } from 'src/app/digitalvenue/services/extra.service';
import { DVMService } from 'src/app/digitalvenue/services/dvm.service';
import { DVM_CONFIG } from 'src/app/digitalvenue/digitalvenue.configuration';
import { DVMConfiguration } from 'src/app/digitalvenue/dvmconfiguration.model';
declare var $: any;
import * as $ from 'jquery';
import { ModalsService } from '../modals.service';
import { PanoViewModalComponent } from '../pano-view-modal/pano-view-modal.component';
import * as globals from '../../../digitalvenue/globals';
import { FlowControlService } from 'src/app/digitalvenue/services/flow-control.service';
import { Transaction, CustomerPlan, Seat } from '../../models';
import { APIConnectionService } from 'src/app/digitalvenue/services/api-connection.service';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { IsolatedSeatsApiService } from 'src/app/digitalvenue/services/isolated-seats-api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-extra-modal',
  templateUrl: './extra-modal.component.html',
  styleUrls: ['./extra-modal.component.scss']
})
export class ExtraModalComponent implements OnInit {
  reject;
  success;
  title: string;
  message: string;
  isDarkTheme: boolean;
  parkingSlotC = 0;
  parkingSlotW = 0;
  lexusSlot = 0;
  transactionExist: Transaction;
  extraPrices = {
    parking: 4250,
    lexus: 5000
  };
  allExtrasSlots = [];

  get extrasBalance() {
    return this.parkingSlotC * this.extraService.extraPrices.parking_c +
    this.parkingSlotW * this.extraService.extraPrices.parking_w +
    this.lexusSlot * this.extraService.extraPrices.lexus;
  }

  constructor(@Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
              private modalService: BsModalService,
              public bsModalRef: BsModalRef,
              private dvmService: DVMService,
              private themeSelector: ThemeSelectorService,
              public extraService: ExtraService,
              private flowService: FlowControlService,
              private connectionService: APIConnectionService,
              private authService: DjangoSessionAuthenticationService,
              private flowControl: FlowControlService,
              private isolatedSeatsApiService: IsolatedSeatsApiService,
              private router: Router) {
  }

  ngOnInit() {
    let venue_id = this.dvmConfig.venue_id;
    if (this.flowControl.currentVenue) {
      venue_id = this.flowControl.getCurrentMap();
    }
    this.dvmService.viewer3d.getThumbnail({ venue_id,
      view_id: 'S_Lexus(INT)' }, true).then((img) => {
      const element = document.querySelector('#lexus-preview-modal') as HTMLElement;
      element.innerHTML = '';
      element.style.backgroundImage = `url('${img}')`;
    });
  }

  openLexusView() {
    let heigh;
    if (globals.isMobile() || window.innerWidth < 1200) {
      heigh = document.getElementById('extra-modal').offsetHeight;
    }
    const config: ModalOptions = {
      animated: true,
      keyboard: false,
      ignoreBackdropClick: true,
      class: 'modal-lg modal-dialog-centered',
      initialState: {
        seatId: 'S_Lexus(INT)-1-3',
        parentModalHeight: heigh
      }
    };

    this.modalService.show(PanoViewModalComponent, config);
  }

  public removeLexus(): void {
    if (this.lexusSlot > 0) {
      this.lexusSlot -= 1;
    }
  }

  public addLexus(): void {
    this.lexusSlot += 1;
  }

  public removeParking(parkingSection): void {
    if (parkingSection === 'Lot C') {
      if (this.parkingSlotC > 0) {
        this.parkingSlotC -= 1;
      }
    } else if (parkingSection === 'Lot W') {
      if (this.parkingSlotW > 0) {
        this.parkingSlotW -= 1;
      }
    }
  }

  public addParking(parkingSection): void {
    if (parkingSection === 'Lot C') {
      this.parkingSlotC += 1;
    } else if (parkingSection === 'Lot W') {
      this.parkingSlotW += 1;
    }
  }

  public confirm(): void {
    this.addParkingToBasket('Lot C', this.parkingSlotC);
    this.addParkingToBasket('Lot W', this.parkingSlotW);
    this.addLexusToBasket(this.lexusSlot, 4);
    this.createHoldTransaction();
    this.bsModalRef.hide();
  }

  /**
   * Hides the modal
   */
  decline(): void {
    // this.reject('reject');
    this.bsModalRef.hide();
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

  public addParkingToBasket(parkingSection: string, parkingSlots: number): void {
    if (parkingSection && parkingSlots) {
      for (let i = 0; i < parkingSlots; i++) {
        let price;
        if (parkingSection === 'Lot C') {
          price = this.extraService.extraPrices.parking_c;
        } else if (parkingSection === 'Lot W') {
          price = this.extraService.extraPrices.parking_w;
        }
        const parkingObj: Seat = {
          id: 'S_' + parkingSection + '-GA-' + i,
          section: 'S_' + parkingSection,
          price
        };
        this.allExtrasSlots.push('S_' + parkingSection);
        // this.flowService.Basket[parkingObj.id] = parkingObj;
        // this.flowService.extraBasket.push(parkingObj.section);
      }
    }
  }

  public addLexusToBasket(lexusSlot: number, lexusPasses: number, success = null): void {
    for (let i = 0; i < this.lexusSlot; i++) {
      const lexusObj: Seat = {
        id: 'S_Lexus Club-GA' + lexusPasses + '-' + i,
        section: 'S_Lexus Club',
        price: this.extraPrices.lexus
      };
      // this.flowService.Basket[lexusObj.id] = lexusObj;
      // his.flowService.extraBasket.push(lexusObj.section);
      this.allExtrasSlots.push('S_Lexus Club');


    }
    // success('added');
  }

  createHoldTransaction(): void {
    this.flowService.extraBasket = this.allExtrasSlots;
    const transactionSeats = Object.keys(this.flowService.Basket).concat(this.flowService.seatsToKeep).concat(this.allExtrasSlots);
    this.connectionService.createHoldTransaction(this.flowService.currentPlan, transactionSeats, this.flowService.upgradePlan).subscribe(
      success => {
        this.authService.updateUserData().subscribe(
          response => {
            this.router.navigate(['/checkout', this.flowService.currentPlan]);
          },
          error => {
            console.error(error);
            this.errorModal(error.error.message);
            // If error, shows a modal with the error code
            // this.errorCodeService.getErrorByCode(1004).subscribe(
            //   errorText => {
            //     // displays an error modal
            //     this.errorModal(errorText);
            //   }
            // );
          }
        );
      },
      error => {
        const errorMessage = error.error;
        switch (errorMessage.code) {
          case 'TCR03':
            this.errorModal(errorMessage.message);
            break;
          case 'TCRRS03':
            this.isolatedSeatsApiService.setPendingSeats(errorMessage.seats);
            this.isolatedSeatsApiService.printIsolatedSeats(errorMessage.seats);
            for (let i = 0; i < errorMessage.seats.length; i++) {
              errorMessage.seats[i] = errorMessage.seats[i].split('S_')[1];
            }
            this.errorModal(`The selected seats are leaving isolated seats: ${errorMessage.seats}`);
            break;
          default:
            console.error(error);
            this.errorModal(error.error.message);
            // If error, shows a modal with the error code
            // this.errorCodeService.getErrorByCode(1005).subscribe(
            //   errorText => {
            //     // displays an error modal
            //     this.errorModal(errorText);
            //   }
            // );
            break;
        }
      });
  }


  public errorModal(errorMessage): void  {
    const initialState = {
      animated: true,
      keyboard: false,
      ignoreBackdropClick: true,
      message: errorMessage
    };
    this.bsModalRef = this.modalService.show(ErrorModalComponent, { class: 'modal-dialog-centered', initialState });
  }

}

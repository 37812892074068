import { Component, OnInit } from '@angular/core';
import { ThemeSelectorService, LocalStorageService } from '../services';

@Component({
  selector: 'app-common',
  templateUrl: './common.component.html',
  styleUrls: ['./common.component.scss']
})
export class CommonComponent implements OnInit {

  constructor(private themeSelector: ThemeSelectorService,
              private storageService: LocalStorageService) { }

  ngOnInit() {
    if (this.storageService.storageAvailable('localStorage')) {
      this.storageService.isStorageAvailable = true;
    }
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

}
